<template>
    <div class="home-container" id="home-container">
        <div class="mask" :class="{ deactive: !maskActive }"></div>
        <div
            class="mobile-sticky-header"
            data-scroll
            data-scroll-id="inner-header"
        ></div>
        <mouse-changer :type="mouseTypes.Landing">
            <div :class="{ transition: slideTransition }" class="page-slider">
                <div @click="slideClickHandler" class="slider-list">
                    <div
                        v-for="(item, index) in slides"
                        :class="{
                            'swiper-slide-active': index === activeSlideIndex,
                            'swiper-slide-prev': index === prevSlideIndex,
                            'swiper-slide-next': index === nextSlideIndex
                        }"
                        :key="index"
                        class="slider-item"
                    >
                        <div class="background-container pointer">
                            <img
                                :src="item.path"
                                class="image"
                                alt="our story"
                            />
                        </div>
                        <grain-effect />
                        <div class="lighting-container pointer">
                            <picture>
                                <source
                                    class="image"
                                    media="(max-width: 767px)"
                                    :srcset="item.mobile"
                                />

                                <img
                                    :src="item.path"
                                    class="image"
                                    alt="our story"
                                />
                            </picture>
                        </div>
                        <div class="fancy-text-padding">
                            <fancy-text
                                :title="item.title"
                                :index="index + 1"
                                :id="`fancy-home-title${index}`"
                                :animate-on-observe="false"
                                :color="item.color"
                            />
                        </div>
                    </div>
                </div>
            </div>
        </mouse-changer>
        <div class="mobile-sides">
            <div
                v-for="(item, index) in slides"
                :key="index"
                @click="mobileExplore(item)"
                class="slider-item"
            >
                <grain-effect />

                <picture>
                    <source
                        :srcset="item.mobile"
                        data-scroll
                        :data-scroll-id="`imageParallax-${index}`"
                        class="image"
                        alt="our story"
                    />

                    <img
                        :src="item.path"
                        data-scroll
                        :data-scroll-id="`imageParallax-${index}`"
                        class="image"
                        alt="our story"
                    />
                </picture>
                <div class="content">
                    <span class="header-title">{{ item.title }}</span>
                    <!--                    <div class="explore-container">-->
                    <!--                        <div class="explore-dot"></div>-->
                    <!--                        <span class="title">Explore </span>-->
                    <!--                    </div>-->
                </div>
            </div>
            <Footer />
        </div>
        <custom-cursor v-if="showCursor" :callback="mouseMove" />
    </div>
</template>

<script>
import FancyText from "@/components/custom/FancyText";
import CustomCursor from "@/components/custom/CustomCursor";
import MouseChanger from "@/components/custom/MouseChanger";
import GrainEffect from "@/components/custom/GrainEffect";
import TitleAnimation from "@/mixins/TitleAnimation";
import Footer from "@/components/Footer";
import { mapActions, mapGetters } from "vuex";
import debounce from "lodash/debounce";

export default {
    data() {
        return {
            slideTransition: false,
            id: `fancy-home-title0`,
            activeSlideIndex: 0,
            debounceEvent: null,
            maskActive: true,
            render: true
        };
    },
    components: {
        FancyText,
        CustomCursor,
        MouseChanger,
        GrainEffect,
        Footer
    },
    mixins: [TitleAnimation],
    computed: {
        ...mapGetters({
            disableMouseMove: "mouseCursor/getDisableMouseMove",
            menu: "menu/getMenu",
            device: "resize/geDevice",
            lang: "language/getLang",
            scroll: "scroll/getScroll"
        }),
        slides() {
            return (
                this.menu
                    .filter(
                        item =>
                            item?.menu_type === "main_menu" &&
                            item.configs?.includes("show-on-homepage")
                    )
                    .map(item => ({
                        title: item.titles?.[this.lang]?.title,
                        url_slug: item.url_slug,
                        path: item.media?.cover?.devices?.desktop,
                        mobile: item.media?.optional_image?.devices?.desktop,
                        color: item?.titles?.[this.lang]?.primaryColor
                    })) || []
            );
        },
        nextSlideIndex() {
            return this.clamp(
                this.activeSlideIndex + 1,
                0,
                this.slides?.length - 1
            );
        },
        prevSlideIndex() {
            return this.clamp(
                this.activeSlideIndex + -1,
                0,
                this.slides?.length - 1
            );
        },
        headerHeight() {
            let height = 200;

            if (this.device?.innerHeight < 861) {
                height = 180;
            }
            if (this.device?.innerHeight < 751) {
                height = 160;
            }
            if (this.device?.innerHeight < 651) {
                height = 120;
            }

            return height;
        },
        imageHeight() {
            let height = 550;

            if (this.device?.innerHeight < 861) {
                height = 450;
            }
            if (this.device?.innerHeight < 751) {
                height = 350;
            }
            if (this.device?.innerHeight < 651) {
                height = 300;
            }
            if (this.device?.innerHeight < 501) {
                height = 250;
            }

            return height;
        },
        slideWidthClip() {
            let height = 200;

            if (this.device?.innerHeight < 861) {
                height = 150;
            }
            if (this.device?.innerHeight < 751) {
                height = 10;
            }
            if (this.device?.innerHeight < 651) {
                height = 60;
            }
            if (this.device?.innerHeight < 501) {
                height = 30;
            }

            return height;
        },
        showCursor() {
            return this.device.size > 1024;
        }
    },
    methods: {
        ...mapActions({
            setDisableMouseMove: "mouseCursor/setDisableMouseMove",
            setPageIsLoaded: "menu/setPageIsLoaded"
        }),
        clamp(num, min, max) {
            return num < min ? max : num > max ? min : num;
        },
        changeActiveIndex(direction) {
            this.activeSlideIndex = this.clamp(
                this.activeSlideIndex + direction,
                0,
                this.slides?.length - 1
            );
        },
        slideOnnAnimation(callBack) {
            this.id = `fancy-home-title${this.activeSlideIndex}`;
            let duration = 1.3;
            let delay = 0.6;
            let ease = this.CustomEase.create(
                "custom",
                "M0,0,C0.098,0,0,1,1,1"
            );

            this.initTitleAnimation();

            this.gsap.to(`.fancy-content`, {
                opacity: 1,
                delay: 0.5
            });
            this.gsap.fromTo(
                ".swiper-slide-active .background-container",
                {
                    opacity: 0
                },
                {
                    opacity: 1,
                    delay: 0.2,
                    ease,
                    duration
                }
            );
            this.gsap.set(".swiper-slide-active .background-container .image", {
                x: 0,
                y: 0
            });
            this.gsap.set(".swiper-slide-active .lighting-container", {
                x: 0,
                y: 0
            });
            this.gsap.to(".swiper-slide-active .lighting-container", {
                clipPath: `inset(${this.headerHeight}px 0px calc(100vh - ${this
                    .headerHeight + this.imageHeight}px))`,
                duration,
                delay,
                ease
            });
            this.gsap.to(".swiper-slide-active .lighting-container .image", {
                scale: 1,
                duration,
                delay,
                ease,
                onComplete: () => {
                    this.slideTransition = true;
                    this.setDisableMouseMove(false);
                    if (typeof callBack === "function") callBack();
                }
            });
        },
        slideOffAnimation(direction, callBack) {
            let ease = this.CustomEase.create(
                "custom",
                "M0,0,C0.02,0.226,0.335,1,1,1"
            );
            let duration = 0.6;

            this.FancyTextFadeOff(direction);

            this.gsap.to(`.swiper-slide-${direction} .lighting-container`, {
                clipPath: `inset(${this.headerHeight + this.imageHeight}px ${
                    this.slideWidthClip
                }px calc(100vh - ${this.headerHeight + this.imageHeight}px))`,
                duration,
                ease
            });
            this.gsap.to(
                `.swiper-slide-${direction} .lighting-container .image`,
                {
                    transform: "scale(1.2)",
                    duration,
                    ease
                }
            );
            this.gsap.to(`.swiper-slide-${direction} .background-container`, {
                opacity: 0,
                duration,
                ease,
                onComplete: () => {
                    if (typeof callBack === "function") callBack();
                }
            });
        },
        slideExploreAnimation() {
            this.scroll?.stop();
            this.FancyTextExploreAnimation();
            this.gsap.to(
                `.swiper-slide-active .lighting-container,.swiper-slide-duplicate-active .lighting-container`,
                {
                    clipPath: `inset(0px 0px)`,
                    transform: "translate(0,0)",
                    duration: 0.8,
                    ease: this.CustomEase.create(
                        "custom",
                        "M0,0,C0.02,0.226,0.335,1,1,1"
                    )
                }
            );
            this.gsap.to(
                `.swiper-slide-active .lighting-container .image,.swiper-slide-duplicate-active .image`,
                {
                    transform: "scale(1.2) translate(0,0)",
                    duration: 0.8,
                    ease: this.CustomEase.create(
                        "custom",
                        "M0,0,C0.02,0.226,0.335,1,1,1"
                    ),
                    onComplete: () => {
                        this.$router.push(
                            `/${this.lang}/${
                                this.slides?.[this.activeSlideIndex]?.url_slug
                            }`
                        );
                    }
                }
            );
        },
        FancyTextExploreAnimation() {
            let ease = "power1.out";

            this.gsap.to(
                `.swiper-slide-active  .line-wrapper,.swiper-slide-duplicate-active .line-wrapper`,
                {
                    transform: "translateY(-100px)",
                    opacity: 0,
                    ease,
                    duration: 0.6
                }
            );
            this.gsap.to(
                `.swiper-slide-active  .index,.swiper-slide-duplicate-active .index`,
                {
                    transform: "translateY(-100px)",
                    opacity: 0,
                    ease,
                    duration: 0.6
                }
            );
            this.gsap.to(
                `.swiper-slide-active  .dot,.swiper-slide-duplicate-active .dot`,
                {
                    transform: "translateY(-100px)",
                    opacity: 0,
                    ease,
                    duration: 0.6
                }
            );
            this.gsap.to(
                `.swiper-slide-active .up,.swiper-slide-duplicate-active .up`,
                {
                    clipPath: "inset(-300px 0 100% 0)",
                    ease,
                    duration: 0.6
                }
            );
            this.gsap.to(
                `.swiper-slide-active  .down,.swiper-slide-duplicate-active .down`,
                {
                    clipPath: "inset(0px 0 -300px 0)",
                    ease,
                    duration: 0.6
                }
            );
            this.gsap.to(
                `.swiper-slide-active  .fancy-content,.swiper-slide-duplicate-active .fancy-content`,
                {
                    y: "-=100",
                    ease,
                    delay: 0.2,
                    opacity: 0,
                    duration: 0.6
                }
            );
        },
        slideNext(direction) {
            let directionClass = direction > 0 ? "prev" : "next ";

            this.changeActiveIndex(direction);
            setTimeout(() => {
                this.slideOffAnimation(directionClass, this.slideOnnAnimation);
            }, 10);
        },
        slideClickHandler(event) {
            if (this.disableMouseMove) return;
            this.setDisableMouseMove(true);

            if (event.clientX < innerWidth / 3) {
                this.slideNext(-1);
            } else if (event.clientX > (innerWidth * 2) / 3) {
                this.slideNext(1);
            } else {
                this.slideExploreAnimation();
            }
        },
        mobileExplore(item) {
            this.$router.push(`/${this.lang}/${item?.url_slug}`);
        },
        wheel(event) {
            if (this.disableMouseMove || this.device.size < 1024) return;
            this.setDisableMouseMove(true);
            if (event.deltaY < 0) {
                this.slideNext(-1);
            } else if (event.deltaY > 0) {
                this.slideNext(1);
            }
        },
        wheelEventHandler() {
            if (this.device.size < 1024) return;
            this.debounceEvent = debounce(this.wheel, 500, { leading: true });
            window.addEventListener("wheel", this.debounceEvent, {
                passive: true
            });
        },
        resize() {
            this.gsap.to(".swiper-slide-active .lighting-container", {
                clipPath: `inset(${this.headerHeight}px 0px calc(100vh - ${this
                    .headerHeight + this.imageHeight}px))`,
                overwrite: true
            });
        },
        resizeHandler() {
            window.addEventListener("resize", this.resize, false);
        },
        mobileParallax() {
            if (this.device.size < 1024) {
                let images = document.querySelectorAll(
                    "#home-container .slider-item .image"
                );
                let pr = new this.simpleParallax(images, {
                    customContainer: document.querySelector("body"),
                    scale: 1.5,
                    orientation: "down"
                });
                pr.refresh();
            }
        }
    },
    mounted() {
        this.$nextTick(() => {
            this.mobileParallax();
            this.resizeHandler();
            this.setDisableMouseMove(true);
            this.slideOnnAnimation();
            this.wheelEventHandler();
        });
        setTimeout(() => {
            this.maskActive = false;
        }, 300);
    },
    beforeDestroy() {
        window.removeEventListener("wheel", this.debounceEvent);
        window.removeEventListener("resize", this.resizeHandler);
    }
};
</script>

<style lang="scss">
$header-height: 200;
$image-height: 550;
$ease-out: cubic-bezier(0.24, 0.3, 0.23, 0.98);

.pointer {
    cursor: pointer;
}

.mask {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    background-color: black;
    opacity: 1;
    transition: 1s;
    z-index: 99999999;
    display: none;
    pointer-events: none;
    &.deactive {
        opacity: 0;
    }
    @media (max-width: 1023px) {
        display: block;
    }
}

.home-container {
    background: #0a141c;
    position: absolute;
    width: 100%;
    height: 100vh;
    left: 0;
    top: 0;
    z-index: 21;
    @media only screen and (max-width: 1023px) {
        position: relative;
        height: auto;
        left: unset;
        top: unset;
    }

    .mobile-sticky-header {
        display: none;
        height: 300px;
        position: absolute;
        top: 0;
        left: 0;
        pointer-events: none;
        background: transparent;
        @media only screen and (max-width: 1023px) {
            display: block;
        }
    }

    .fancy-text-padding {
        margin: 0 75px;
        position: relative;
        @media only screen and (max-width: 1650px) and (min-width: 1440px) {
            margin: 0 165px;
        }
        @media only screen and (max-width: 1439px) and (min-width: 1366px) {
            margin: 0 128px;
        }
        @media only screen and (max-width: 1365px) and (min-width: 1024px) {
            margin: 0 47px;
        }
    }

    .page-slider {
        background: #0a141c;
        @media only screen and (max-width: 1023px) {
            display: none;
        }

        &.transition {
            .slide-item {
                transition: all 0.1s 1s;
            }
        }

        .background-container {
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            height: 100vh;
            opacity: 0;
            overflow: hidden;

            .image {
                width: 115%;
                height: 106%;
                left: -7.5%;
                top: -3%;
                position: relative;
                opacity: 0.2;
            }

            &:after {
                content: "";
                background: rgba(0, 0, 0, 0.2);
                width: 100%;
                height: 100%;
                position: absolute;
                left: 0;
                top: 0;
            }
        }

        .slider-list {
            position: absolute;
            left: 0;
            top: 0;
            height: 100vh;
            width: 100%;

            .slider-item {
                background: #0a141c;
                overflow: hidden;
                position: absolute;
                left: 0;
                top: 0;
                width: 100%;
                height: 100%;
                opacity: 0;
                pointer-events: none;
                transition: opacity 0.6s 0.6s ease;

                &.swiper-slide-active {
                    opacity: 1;
                    pointer-events: auto;
                }
            }
        }

        .lighting-container {
            position: absolute;
            width: 115%;
            height: 100vh;
            left: -7.5%;
            clip-path: inset(
                ($header-height + $image-height) + px 200px
                    calc(100vh - #{$header-height + $image-height}px)
            );
            z-index: 1;
            will-change: transform;

            @media only screen and (max-height: 860px) and (min-height: 750px) {
                $image-height: 450;
                $header-height: 180;
                clip-path: inset(
                    ($header-height + $image-height) + px 150px
                        calc(100vh - #{$header-height + $image-height}px)
                );
            }
            @media only screen and (max-height: 750px) and (min-height: 650px) {
                $image-height: 350;
                $header-height: 160;
                clip-path: inset(
                    ($header-height + $image-height) + px 100px
                        calc(100vh - #{$header-height + $image-height}px)
                );
            }
            @media only screen and (max-height: 650px) and (min-height: 501px) {
                $image-height: 300;
                $header-height: 120;
                clip-path: inset(
                    ($header-height + $image-height) + px 60px
                        calc(100vh - #{$header-height + $image-height}px)
                );
            }
            @media only screen and (max-height: 500px) {
                $image-height: 250;
                $header-height: 150;
                clip-path: inset(
                    ($header-height + $image-height) + px 30px
                        calc(100vh - #{$header-height + $image-height}px)
                );
            }

            .image {
                transform: scale(1.4);
                will-change: transform;
            }
        }

        .fancy-text {
            top: ($header-height + $image-height) + px;
            transition: top 0.6s ease-out;
            @media only screen and (max-height: 860px) and (min-height: 751px) {
                $image-height: 450;
                $header-height: 180;
                top: ($header-height + $image-height) + px;
            }
            @media only screen and (max-height: 750px) and (min-height: 651px) {
                $image-height: 350;
                $header-height: 160;
                top: ($header-height + $image-height) + px;
            }
            @media only screen and (max-height: 650px) and (min-height: 501px) {
                $image-height: 300;
                $header-height: 120;
                top: ($header-height + $image-height) + px;
            }
            @media only screen and (max-height: 500px) {
                $image-height: 250;
                $header-height: 120;
                top: ($header-height + $image-height) + px;
            }
            @media only screen and (max-width: 1023px) and (min-width: 768px) {
                width: calc(100% - 90px);
            }
            @media only screen and (max-width: 767px) {
                width: calc(100% - 30px);
            }

            .text-container {
                left: 42%;
                @media only screen and (max-width: 1650px) and (min-width: 1024px) {
                    left: 35%;
                }
            }

            .title {
                font-size: 144px;
                width: 100%;
                letter-spacing: 0.2em;

                // @media only screen and (max-width: 1650px) and (min-width: 1024px) {
                //     font-size: 55px;
                // }
                // @media only screen and (max-width: 1023px) and (min-width: 768px) {
                //     font-size: 55px;
                // }
                @media (max-width: 1679px) {
                    font-size: 89px;
                }
                @media (max-width: 1365px) {
                    font-size: 55px;
                }
                @media only screen and (max-width: 1023px) {
                    text-align: left;
                    width: 100%;
                    padding-left: 10px;
                }
                @media only screen and (max-width: 767px) {
                    font-size: 34px;
                }
            }
        }
    }

    .image {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }

    .explore-container {
        align-items: center;
        cursor: pointer;

        @media only screen and (max-width: 1023px) {
            display: flex;
        }
        @media only screen and (max-width: 767px) {
            left: 30px;
        }

        .explore-dot {
            @include circle(10);
            background-color: white;
            margin-right: 13px;
        }

        .title {
            font-size: 21px;
            color: white;
            font-style: italic;
            letter-spacing: 1.3px;
        }
    }

    .mobile-sides {
        display: none;
        padding-top: 167px;
        @media only screen and (max-width: 1023px) {
            display: block;
        }

        .slider-item {
            width: 100%;
            height: 345px;
            position: relative;
            overflow: hidden;
            margin-top: 21px;
            display: flex;
            align-items: center;
            justify-content: center;
            transition: all 0.6s $ease-out;

            &:first-child {
                margin-top: 0;
            }

            @media only screen and (max-width: 767px) {
                height: 245px;
            }
        }

        .simpleParallax {
            height: 100%;
            width: 100%;
        }

        .content {
            position: absolute;
            bottom: 21.5px;
            left: 47px;
            z-index: 99;
            transition: all 0.6s $ease-out;

            @media only screen and (max-width: 767px) {
                left: 16px;
            }
        }

        .image {
            transform-origin: center;
        }

        .header-title {
            font-weight: normal;
            font-size: 55px;
            letter-spacing: 0.2em;
            text-align: left;
            color: #fff;
            display: block;
            transition: all 0.6s $ease-out;
            cursor: pointer;
            @media only screen and (max-width: 767px) {
                font-size: 34px;
                margin-bottom: 5px;
            }
        }
    }
}
</style>
