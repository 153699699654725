import {mapGetters} from "vuex";

export default {
    data() {
        return {
            upTitleTimeOut: null,
            downTitleTimeOut: null,
        }
    },
    computed: {
        ...mapGetters({
            disableMouseMove: 'mouseCursor/getDisableMouseMove',
        }),
    },
    methods: {
        loadAnimation(element, from, to) {
            this.gsap.set(element, from)
            this.gsap.to(element, {
                ...to,
                ease: this.CustomEase.create("custom", "M0,0,C0.114,0,0.23,0.964,1,1"),
                duration: .6,
                opacity: 1,
                force3D: false,
            })
        },
        initTitleAnimation() {
            if (this.upTitleTimeOut) clearTimeout(this.upTitleTimeOut)
            if (this.downTitleTimeOut) clearTimeout(this.downTitleTimeOut)
            let indexed = document.querySelector(`#${this.id} .index`)
            let xPercent = typeof this.xPercent === 'number' ? this.xPercent : -50
            this.gsap.set(`#${this.id} .fancy-content,#${this.id} .fancy-content .title`, {
                transform: 'none'
            })
            return new Promise((resolve) => {

                this.loadAnimation(`#${this.id} .line-wrapper`, {
                    transform: 'scaleX(0)',
                }, {
                    transform: 'scaleX(1)',
                });
                if (indexed) {
                    this.loadAnimation(`#${this.id} .dot`, {
                        transform: 'scale(0)',
                    }, {
                        transform: 'scale(1)',
                    });
                    this.loadAnimation(`#${this.id} .index`, {
                        y: 20,
                        opacity: 0
                    }, {
                        y: 0,
                        opacity: 1
                    });
                }
                this.loadAnimation(`#${this.id} .up .title.colored`, {
                    xPercent: xPercent,
                    yPercent: 50,
                }, {
                    xPercent: xPercent,
                    yPercent: 0,
                    overwrite: true,
                    delay: .4,
                })
                this.loadAnimation(`#${this.id} .up .title.shadow`, {
                    xPercent: xPercent,
                    yPercent: 50,
                }, {
                    xPercent: xPercent,
                    yPercent: 0,
                    overwrite: true,
                    delay: .45,
                    onComplete: () => {
                        resolve(true);
                    }
                })
                this.loadAnimation(`#${this.id} .down .title`, {
                    xPercent: xPercent,
                    yPercent: -50,
                }, {
                    xPercent: xPercent,
                    yPercent: 0,
                    overwrite: true,
                    delay: .4,
                })
            });
        },
        mouseMove(event) {
            if (this.disableMouseMove) return
            let deltaY = (event.y - innerHeight / 2) / 13
            let deltaX = (event.x - innerWidth / 2) / 15
            let ease = this.CustomEase.create("custom", "M0,0,C0.124,0.246,0.218,0.964,1,1");
            let dotContainer = document.querySelector(`.swiper-slide-active .dot`)
            let lightingContainer = document.querySelector(`.swiper-slide-active .lighting-container .image`)


            let titleAnimation = {
                y: deltaY,
                x: deltaX,
                // rotate: .1,
                duration: .85,
                ease,
                force3D: false,
                overwrite: true,
            }

            //line-wrapper
            this.gsap.to('.swiper-slide-active .line-wrapper', {
                y: deltaY,
                force3D: false,
                // overwrite: true,
                duration: 1,
                ease,
            })
            if (dotContainer) this.gsap.to('.swiper-slide-active .index', {
                y: deltaY,
                force3D: false,
                // overwrite: true,
                duration: 1,
                ease,
            })
            if (dotContainer) this.gsap.to('.swiper-slide-active .dot', {
                y: deltaY,
                force3D: false,
                // overwrite: true,
                duration: 1,
                ease,
            })

            //image-container
            if (lightingContainer) this.gsap.to('.swiper-slide-active .lighting-container .image', {
                y: deltaY,
                x: deltaX,
                // rotate: .1,
                duration: 1,
                force3D: false,
                overwrite: true,
                ease
            })
            if (lightingContainer) this.gsap.to('.swiper-slide-active .lighting-container', {
                y: deltaY,
                x: deltaX,
                // rotate: .1,
                duration: 1,
                force3D: false,
                overwrite: true,
                ease
            })
            if (lightingContainer) this.gsap.to('.swiper-slide-active .background-container .image', {
                y: deltaY,
                x: deltaX,
                // rotate: .1,
                duration: 1,
                force3D: false,
                overwrite: true,
                ease
            })
            //title
            this.gsap.to('.swiper-slide-active .up .title.colored', titleAnimation)
            this.gsap.to('.swiper-slide-active .fancy-content', titleAnimation)

            this.upTitleTimeOut = setTimeout(() => {
                let className = this.titleReverse ? 'down' : 'up'
                this.gsap.to(`.swiper-slide-active .${className} .title.shadow`, titleAnimation)
            }, 100)
            this.downTitleTimeOut = setTimeout(() => {
                this.gsap.to('.swiper-slide-active .down .title.colored', titleAnimation)
            }, 200)

        },
        FancyTextFadeOff(direction, callback) {
            let ease = this.CustomEase.create("custom", "M0,0,C0.294,0.294,0.69,1,1,1");
            let duration = .6;
            let dotContainer = document.querySelector(`.swiper-slide-${direction} .dot`)

            this.gsap.to(`.swiper-slide-${direction}  .line-wrapper`, {
                scaleX: 0,
                opacity: 0,
                ease,
                duration,
            });
            if (dotContainer) this.gsap.to(`.swiper-slide-${direction}  .dot`, {
                scale: 0,
                ease,
                duration,
            });
            if (dotContainer) this.gsap.to(`.swiper-slide-${direction}  .index`, {
                y: 20,
                opacity: 0,
                ease,
                duration,
            });
            this.gsap.to(`.swiper-slide-${direction}  .up .title.colored`, {
                yPercent: 65,
                opacity: 0,
                ease,
                duration,
            });
            this.gsap.to(`.swiper-slide-${direction}  .up .title.shadow`, {
                yPercent: 65,
                ease,
                opacity: 0,
                duration,
            });
            this.gsap.to(`.swiper-slide-${direction}  .down .title`, {
                yPercent: -65,
                ease,
                opacity: 0,
                duration,
                onComplete: () => {
                    if (typeof callback === 'function') callback()
                }
            });
        },
    }
}

