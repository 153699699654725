<template>
    <div class="main-template">
        <age-verification @onVerify="verified = true" v-if="!ageIsVerified" />
        <template v-else>
            <div
                data-scroll-container
                id="image-load-container"
                class="scroll-container"
            >
                <div class="scroll-section">
                    <div
                        class="data-scroll-sticky"
                        id="data-scroll-header-target"
                    ></div>
                    <Header
                        :components="components"
                        :showResizedHeader="showResizedHeader"
                        data-scroll
                        data-scroll-sticky
                        data-scroll-target="#data-scroll-header-target"
                        v-if="routeLoad"
                    />
                    <home-page
                        v-if="(isHomePage || !pageIsLoaded) && !hideHome"
                    />
                    <div
                        class="smart-components-target"
                        @click="scrollToClick(components[1].paginationTitle)"
                    >
                        <component
                            v-for="component in components"
                            :is="component.component"
                            :id="component.paginationTitle"
                            :data="component.data"
                            :name="component.paginationTitle"
                            :lightMode="lightMode"
                            :config="component.config"
                            :key="component.id"
                        ></component>
                    </div>
                    <Footer
                        v-if="!isHomePage"
                        :darkFooter="!lightMode && !isWines"
                    />
                </div>
            </div>
            <scale-component />
            <portal-target name="destination"></portal-target>
            <transition name="pagination">
                <div
                    class="page-pagination"
                    v-if="showPagePagination && !isHomePage && pageIsLoaded"
                    :class="{ lightMode }"
                >
                    <div
                        v-for="(item, index) in components"
                        :key="index"
                        @click="scrollTo(item.paginationTitle)"
                        :class="{
                            active: item.paginationTitle === activeComponent
                        }"
                        class="list-item"
                    >
                        <div
                            class="title-container"
                            v-if="
                                item.config.header.full_list_title !== null &&
                                    item.config.useHeader === '1'
                            "
                        >
                            <span class="title">{{
                                item.paginationTitle
                            }}</span>
                        </div>
                        <div
                            class="left"
                            v-if="
                                item.config.header.full_list_title !== null &&
                                    item.config.useHeader === '1'
                            "
                        >
                            <div class="dot"></div>
                            <div class="slash"></div>
                        </div>
                    </div>
                </div>
            </transition>
            <video-player :videoPath="videoPath" />
            <custom-cursor v-if="showCursor" />
        </template>
    </div>
</template>

<script>
import Header from "@/components/Header";
import Footer from "@/components/Footer";
import { mapActions, mapGetters } from "vuex";
import LocomotiveScroll from "locomotive-scroll";
import "locomotive-scroll/dist/locomotive-scroll.css";
import ImageScale from "@/mixins/ImageScale";
import HistoryScaleSlider from "@/components/PageComponents/HistoryScaleSlider";
import HomePage from "@/views/home-page";
import VideoPlayer from "@/components/custom/VideoPlayer";
import AgeVerification from "@/components/AgeVerification";
import HeaderTitle from "@/components/custom/HeaderTitle";
import ScaleComponent from "@/components/custom/ScaleComponent";
import imagesLoaded from "imagesloaded";
import isEqual from "lodash/isEqual";
import CustomCursor from "@/components/custom/CustomCursor";
import debounce from "lodash/debounce";

export default {
    data() {
        return {
            breakScroll: true,
            activeComponent: "",
            hideHome: true,
            prevRoute: null,
            verified: false,
            showResizedHeader: false,
            lkScroll: null,
            loadComponentsNumber: 0,
            updareScroll: true,
            currentWindowSize: {
                height: innerHeight,
                width: innerWidth
            }
        };
    },
    components: {
        Header,
        Footer,
        HistoryScaleSlider,
        HomePage,
        VideoPlayer,
        AgeVerification,
        HeaderTitle,
        ScaleComponent,
        CustomCursor
    },
    computed: {
        ...mapGetters({
            routeLoad: "route/getRouteLoad",
            scaleContent: "scaleComponent/getContent",
            scroll: "scroll/getScroll",
            data: "menu/getPage",
            pageIsLoaded: "menu/getPageIsLoaded",
            getLang: "language/getLang",
            device: "resize/geDevice",
            videoContent: "video/getContent",
            lang: "language/getLang",
            activePage: "menu/getCurrentPageMenuData",
            currentPageMenuData: "menu/getCurrentPageMenuData",
            pending: "menu/getPending",
            transparentPercentage: "header/getTransparentPercentage",
            transparentPercentage2: "header/getTransparentPercentage2",
            darkTemplate: "header/getDarkTemplate",
            darkTemplate2: "header/getDarkTemplate2",
            scrollBreak: "video/getScrollBreak"
        }),
        isWines() {
            return (
                (this.$route.params.lang === "wines" &&
                    this.$route.params.path1?.length) ||
                (this.$route.params.path1 === "wines" &&
                    this.$route.params.path2?.length)
            );
        },
        components() {
            if (!this.data?.data || this.pending) return [];
            // const entries = Object.entries(this.data?.data)
            let singleView = Object.values(this.$route?.params)?.includes(
                "singleview"
            );

            let filterComponents = this.currentPageMenuData.secondary_data?.filter(
                compItem => {
                    let enabled = compItem?.enabled === "1";
                    return singleView
                        ? enabled &&
                              (compItem?.singleLayout || compItem?.primary)
                        : enabled && compItem?.listLayout && !compItem?.primary;
                }
            );
            return filterComponents?.map(item => {
                let name = `${item?.selectedComponent}_${item?.unicId}`;
                let secondaryData = this.data?.data?.[
                    `${item?.selectedComponent}_${item?.unicId}`
                ];
                let paginationTitle = item?.primary
                    ? this.data?.primary?.conf?.header?.full_list_title
                    : secondaryData?.conf?.header?.full_list_title;

                return {
                    name,
                    paginationTitle: paginationTitle || item?.selectedComponent,
                    data: singleView
                        ? item?.primary
                            ? this.data?.primary?.data
                            : secondaryData?.data
                        : secondaryData?.data,
                    config: singleView
                        ? item?.primary
                            ? this.data?.primary?.conf
                            : secondaryData?.conf
                        : secondaryData?.conf,
                    component: () => {
                        let t = import(
                            "@/components/PageComponents/" +
                                item?.selectedComponent
                        );

                        t.then(() => {
                            this.loadComponentsNumber += 1;
                            if (
                                this.loadComponentsNumber ===
                                filterComponents.length
                            ) {
                                this.loadComponentsNumber = 0;
                                this.$nextTick(() => {
                                    setTimeout(() => {
                                        if (this.device.size < 1024) {
                                            this.hideRoutChanger();
                                            if (!this.isHomePage) {
                                                this.hideHome = true;
                                            }
                                        } else {
                                            this.initScroll();
                                        }
                                    }, 100);
                                });
                            }
                        });

                        return t;
                    }
                };
            });
        },
        withoutLanguageParams() {
            return this.lang !== this.$route.params?.lang;
        },
        isHomePage() {
            let firstPath = this.withoutLanguageParams
                ? this.$route?.params?.lang
                : this.$route?.params?.path1;

            return firstPath === "home-page" || firstPath === "" || !firstPath;
        },
        fromHomePage() {
            let firstPath = this.withoutLanguageParams
                ? this.prevRoute?.lang
                : this.prevRoute?.path1;

            return firstPath === "home-page" || firstPath === "";
        },
        headerPaddingTop() {
            return this.device?.type === "desktop"
                ? 50
                : this.device?.type === "mobile"
                ? 20
                : 30;
        },
        videoPath() {
            return this.videoContent?.path;
        },
        ageIsVerified() {
            return localStorage.getItem("verified") || this.verified;
        },
        brandHeaderScrollTop() {
            let top = 200;
            if (this.device.size < 1441) {
                top = 150;
            }
            if (this.device.size < 1366) {
                top = 100;
            }
            if (this.device.size < 1024) {
                top = 55;
            }
            return top;
        },
        showPagePagination() {
            return this.activePage?.configs?.includes("use-menu");
        },
        showCursor() {
            return this.device.size >= 1024;
        },
        lightMode() {
            return !this.darkTemplate;
        }
    },
    mixins: [ImageScale],
    methods: {
        ...mapActions({
            setScroll: "scroll/setScroll",
            setShowPageName: "header/setShowPageName",
            setPageIsLoaded: "menu/setPageIsLoaded",
            setLang: "language/setLang",
            setDarkTemplate: "header/setDarkTemplate",
            setDarkTemplate2: "header/setDarkTemplate2",
            setScrollBreak: "video/setScrollBreak"
        }),
        currentElement(args) {
            return Object.entries(args.currentElements).filter(item => {
                return this.components?.filter(comp =>
                    comp.paginationTitle?.includes(item[0])
                )?.length;
            });
        },
        setActiveComponent(args) {
            this.activeComponent = this.currentElement(args).filter(item => {
                return item[1]?.progress < 0.8 && item[1]?.progress > 0.2;
            })?.[0]?.[0];
        },
        scrollTo(id) {
            this.scroll.scrollTo(
                document.querySelector(`[data-scroll-id='${id}']`)
            );
        },
        mobileScroll() {
            document.querySelector("body").addEventListener("scroll", e => {
                if (e.target.scrollTop < 100) {
                    this.breakScroll = true;
                } else {
                    this.breakScroll = false;
                }
            });
        },
        scrollToClick(id) {
            if (this.$route.params.path3 !== undefined) {
                return;
            }
            if (
                this.$route.fullPath.includes("brands") &&
                this.$route.params.path2 !== undefined
            ) {
                return;
            }

            // if (
            //     this.scrollBreak &&
            //     this.$route.params.path2 !== undefined &&
            //     this.$route.fullPath.includes("brands")
            // ) {
            //     return;
            // }
            // this.scroll.update();
            // console.log(id, document.querySelector(`[data-scroll-id='${id}']`));
            // if (
            //     this.$route.params.path2 !== undefined &&
            //     this.$route.fullPath.includes("brands")
            // ) {
            //     this.setScrollBreak(true);
            // } else [this.setScrollBreak(false)];
            if (id === "BrandDescription") {
                this.setScrollBreak(true);
            } else {
                this.setScrollBreak(false);
            }
            if (window.innerWidth < 1024) {
                if (this.breakScroll) {
                    var MIN_PIXELS_PER_STEP = 16;
                    var MAX_SCROLL_STEPS = 30;
                    var target = document.getElementById(id);
                    var scrollContainer = target;
                    do {
                        scrollContainer = scrollContainer.parentNode;
                        if (!scrollContainer) return;
                        scrollContainer.scrollTop += 1;
                    } while (scrollContainer.scrollTop == 0);

                    var targetY = 0;
                    do {
                        if (target == scrollContainer) break;
                        targetY += target.offsetTop;
                    } while ((target = target.offsetParent));

                    var pixelsPerStep = Math.max(
                        MIN_PIXELS_PER_STEP,
                        (targetY - scrollContainer.scrollTop) / MAX_SCROLL_STEPS
                    );

                    var stepFunc = function() {
                        scrollContainer.scrollTop = Math.min(
                            targetY,
                            pixelsPerStep + scrollContainer.scrollTop
                        );

                        if (scrollContainer.scrollTop >= targetY) {
                            return;
                        }

                        window.requestAnimationFrame(stepFunc);
                    };

                    window.requestAnimationFrame(stepFunc);
                    this.breakScroll = false;
                }
            } else {
                if (this.breakScroll) {
                    this.scroll.scrollTo(
                        document.querySelector(`[data-scroll-id='${id}']`)
                    );
                    this.breakScroll = false;
                }
            }
        },
        initScroll() {
            this.$nextTick(() => {
                this.activeComponent = this.components?.[0]?.paginationTitle;
                if (this.scroll) {
                    imagesLoaded(
                        document.querySelector("#image-load-container"),
                        () => {
                            this.hideRoutChanger();
                            this.setPageIsLoaded(true);
                            if (!this.isHomePage) {
                                this.hideHome = true;
                            }
                            this.lkScroll?.destroy();
                            this.scroll?.destroy();
                            setTimeout(() => {
                                this.lkScroll = new LocomotiveScroll({
                                    el: document.querySelector(
                                        "[data-scroll-container]"
                                    ),
                                    smooth: true,
                                    lerp: 0.04,
                                    touchMultiplier: 4,
                                    tablet: {
                                        smooth: true
                                    },
                                    mobile: {
                                        smooth: true
                                    },
                                    smartphone: {
                                        smooth: true
                                    }
                                });
                                this.setScroll(this.lkScroll);
                                this.scrollOnEvent();
                            }, 10);
                        }
                    );
                    return;
                }
                imagesLoaded(
                    document.querySelector("#image-load-container"),
                    () => {
                        this.hideRoutChanger();
                        this.setPageIsLoaded(true);
                        setTimeout(() => {
                            this.lkScroll = new LocomotiveScroll({
                                el: document.querySelector(
                                    "[data-scroll-container]"
                                ),
                                smooth: true,
                                lerp: 0.04,
                                touchMultiplier: 4,
                                tablet: {
                                    smooth: true
                                },
                                mobile: {
                                    smooth: true
                                },
                                smartphone: {
                                    smooth: true
                                }
                            });
                            this.setScroll(this.lkScroll);
                            this.scrollOnEvent();
                        }, 10);
                    }
                );
            });
        },
        clamp(num, min, max) {
            return num < min ? min : num > max ? max : num;
        },
        changePageColor(args) {
            if (!this.transparentPercentage) return;
            let pageHeight = args?.limit?.y;
            let scrollPosition = args?.scroll?.y;
            let percentage = (scrollPosition / pageHeight) * 100;
            this.setDarkTemplate(
                percentage < Number(this.transparentPercentage)
            );
            this.setDarkTemplate2(
                percentage < Number(this.transparentPercentage2)
            );
        },
        scrollOnEvent() {
            let smallHeaderProgress = 0;
            let pageHeaderProgress = 0;
            let innerHeaderProgress = 0;
            let imageParallaxProgress = {};
            let insideScaleProgress = {};
            let brandHeaderProgress = null;
            let scaleProgress = null;

            this.scroll.on("scroll", args => {
                if (args.scroll.y < 300) {
                    this.breakScroll = true;
                } else {
                    this.breakScroll = false;
                }
                //  @TODO remove scroll element position
                // args.scroll.y > 6000
                if (args.scroll.y > 4000) {
                    if (this.updareScroll) {
                        // this.resizeEvent();
                        // debounce(this.resizeEvent(), 700);
                    }
                }
                if (this) if (!this.pageIsLoaded) return;
                this.setActiveComponent(args);
                this.changePageColor(args);
                // Get all current elements : args.currentElements
                if (typeof args.currentElements["page-header"] === "object") {
                    let parallaxObject = args.currentElements["page-header"];
                    let progress = args.currentElements["page-header"].progress;
                    if (!pageHeaderProgress) pageHeaderProgress = progress;

                    this.setShowPageName(progress > 0.53);
                    this.showResizedHeader = progress > 0.87;
                    this.gsap.set(parallaxObject?.el, {
                        clipPath: `inset(0% 0% ${80 *
                            (progress - pageHeaderProgress)}% 0%)`,
                        scale: this.clamp(progress + pageHeaderProgress, 1, 20),
                        force3D: false
                    });
                }
                if (typeof args.currentElements["brandHeader"] === "object") {
                    let brandHeader = args.currentElements["brandHeader"];
                    let progress = args.currentElements["brandHeader"].progress;
                    if (!brandHeaderProgress) brandHeaderProgress = progress;

                    this.setShowPageName(progress > 0.53);
                    this.showResizedHeader = progress > 0.87;

                    this.gsap.set(brandHeader?.el, {
                        y: `-${(progress - brandHeaderProgress) * 400}`
                    });
                    this.gsap.set(".logo-container", {
                        y:
                            (progress - brandHeaderProgress) * 2000 +
                            (progress - brandHeaderProgress) * 400
                    });
                    let a = (progress - brandHeaderProgress) * 2000;
                    let innerheight = document
                        .querySelector("#brand-header-animated")
                        ?.getBoundingClientRect()?.height;
                    let top = this.brandHeaderScrollTop + innerheight / 2;
                    let maxDistance =
                        top +
                        document
                            .querySelector(".logo-container")
                            ?.getBoundingClientRect()?.height /
                            2;
                    if (a > maxDistance) a = maxDistance;
                    this.gsap.set(".animate-logo-container", {
                        y: a
                    });
                }
                if (typeof args.currentElements["small-header"] === "object") {
                    let parallaxObject = args.currentElements["small-header"];
                    let progress =
                        args.currentElements["small-header"].progress;
                    if (!smallHeaderProgress) smallHeaderProgress = progress;
                    this.setShowPageName(progress > smallHeaderProgress);
                    this.showResizedHeader = progress > 0.87;
                    this.gsap.set(parallaxObject?.el, {
                        scale: progress + (1 - smallHeaderProgress),
                        force3D: false
                    });
                }
                if (typeof args.currentElements["inner-header"] === "object") {
                    let parallaxObject = args.currentElements["inner-header"];
                    let progress =
                        args.currentElements["inner-header"].progress;
                    if (!innerHeaderProgress) innerHeaderProgress = progress;

                    this.setShowPageName(progress > innerHeaderProgress);
                    this.showResizedHeader = progress > innerHeaderProgress;
                    this.gsap.set(parallaxObject?.el, {
                        scale: progress + (1 - innerHeaderProgress),
                        force3D: false
                    });
                }
                if (
                    Object.keys(args.currentElements)?.filter(item =>
                        item?.includes("imageParallax")
                    )?.length
                ) {
                    let items = Object.keys(
                        args.currentElements
                    )?.filter(item => item?.includes("imageParallax"));
                    items?.forEach(item => {
                        let parallaxObject = args.currentElements[item];
                        let progress = parallaxObject.progress;
                        if (!imageParallaxProgress[item])
                            imageParallaxProgress[item] = progress;
                        let prgDelta =
                            progress / 3 + (1 - imageParallaxProgress[item]);

                        let y =
                            this.device.size < 769
                                ? (progress - imageParallaxProgress[item]) * 50
                                : (progress - imageParallaxProgress[item]) *
                                  100;

                        this.gsap.set(parallaxObject?.el, {
                            y,
                            scale: this.clamp(prgDelta, 1, 5)
                        });
                    });
                }
                if (typeof args.currentElements["nameParallax"] === "object") {
                    let progress =
                        args.currentElements["nameParallax"].progress;
                    let items = document.querySelectorAll(
                        ".brand-name-slider .name-list"
                    );
                    items.forEach((item, index) => {
                        this.gsap.set(item, {
                            x: progress * (1 - index) * 200
                        });
                    });
                }
                if (typeof args.currentElements["scale"] === "object") {
                    let brandHeader = args.currentElements["scale"];
                    let progress = args.currentElements["scale"].progress;
                    if (!scaleProgress) scaleProgress = progress;

                    this.gsap.set(brandHeader?.el, {
                        transform: `scale(${progress + scaleProgress})`
                    });
                }
                if (
                    Object.keys(args.currentElements)?.filter(item =>
                        item?.includes("inside-scale")
                    )?.length
                ) {
                    let items = Object.keys(
                        args.currentElements
                    )?.filter(item => item?.includes("inside-scale"));
                    items?.forEach(item => {
                        let brandHeader = args.currentElements[item];
                        let progress = args.currentElements[item].progress;
                        if (!insideScaleProgress[item])
                            insideScaleProgress[item] = progress;
                        let prgDelta =
                            progress + (1 - insideScaleProgress[item]);
                        // progress / 1.9 + 1
                        this.gsap.set(brandHeader?.el, {
                            scale: prgDelta,
                            rotate: 0.1,
                            force3D: false
                        });
                    });
                }
                if (typeof args.currentElements["inside-clip"] === "object") {
                    let brandHeader = args.currentElements["inside-clip"];
                    let progress = args.currentElements["inside-clip"].progress;

                    if (progress > 0.4) {
                        this.gsap.set(brandHeader?.el, {
                            clipPath: `inset(0% 0% ${35 * progress}% 0%)`,
                            force3D: false
                        });
                    }
                }
            });
        },
        homeScrollOnEvent() {
            let innerHeaderProgress = 0;

            this.scroll.on("scroll", args => {
                if (typeof args.currentElements["inner-header"] === "object") {
                    let progress =
                        args.currentElements["inner-header"].progress;
                    if (!innerHeaderProgress) innerHeaderProgress = progress;

                    this.setShowPageName(progress > innerHeaderProgress);
                    this.showResizedHeader =
                        progress > innerHeaderProgress + 0.1;
                    // console.log(
                    //     this.showResizedHeader,
                    //     "this.showResizedHeader"
                    // );
                }
            });
        },
        routeChangeLoad(val, oldVal) {
            let v = {
                ...val
            };
            let oldV = {
                ...oldVal
            };
            if (!oldVal) {
                this.setPageIsLoaded(false);
                return;
            }

            if (!this.withoutLanguageParams) {
                delete v.lang;
                delete oldV.lang;
                if (!isEqual(v, oldVal) && val.lang === oldVal.lang)
                    this.setPageIsLoaded(false);
                return;
            }

            if (!isEqual(v, oldVal)) this.setPageIsLoaded(false);
        },
        scrollUpdate() {
            this.lkScroll?.update();
        },
        resizeEvent() {
            this.$nextTick(() => {
                // if (
                //     !(
                //         Math.abs(
                //             window.innerHeight - this.currentWindowSize.height
                //         ) < 100 &&
                //         window.innerWidth === this.currentWindowSize.width
                //     )
                // ) {
                this.lkScroll?.update();
                // }
                this.currentWindowSize = {
                    height: window.innerHeight,
                    width: window.innerWidth
                };
                if (this.isHomePage) {
                    this.showResizedHeader = false;
                    this.setShowPageName(false);
                }
                this.updareScroll = false;
            });
        },
        resizeHandler() {
            window.addEventListener("resize", debounce(this.resizeEvent, 700));
        },
        hideRoutChanger() {
            this.gsap.to(".rout-changer", {
                opacity: 0,
                pointerEvents: "none"
            });
        },
        homeScroll() {
            imagesLoaded(document.querySelector("#home-container"), () => {
                this.lkScroll?.scrollTo("top", {
                    disableLerp: true,
                    duration: 0.01
                });
                this.hideRoutChanger();
                this.lkScroll?.destroy();
                this.scroll?.destroy();
                setTimeout(() => {
                    this.lkScroll = new LocomotiveScroll({
                        el: document.querySelector("[data-scroll-container]"),
                        smooth: true,
                        lerp: 0.04,
                        touchMultiplier: 4,
                        tablet: {
                            smooth: true
                        },
                        mobile: {
                            smooth: true
                        },
                        smartphone: {
                            smooth: true
                        }
                    });
                    this.setScroll(this.lkScroll);
                    this.homeScrollOnEvent();
                }, 10);
                this.showResizedHeader = false;
            });
        },
        verifiedEvent() {
            let interval = false;

            return {
                start: () => {
                    this.$root.$on("age_verified", () => {
                        interval = setInterval(() => {
                            if (document.querySelector("#home-container")) {
                                this.$nextTick(() => {
                                    this.homeScroll();
                                });
                                clearTimeout(interval);
                            }
                        }, 1);
                    });
                }
            };
        }
    },
    watch: {
        "$route.params": {
            handler: function(val, oldVal) {
                // this.setDarkTemplate(this.currentPageMenuData?.primary_template === 'Dark')
                this.prevRoute = oldVal;
                if (this.lkScroll)
                    this.lkScroll.scrollTo("top", {
                        disableLerp: true,
                        duration: 0.01
                    });
                if (this.device.size < 1024) {
                    document.querySelector("body").scrollTop = 0;
                }
                this.setShowPageName(false);
                this.showResizedHeader = false;
                this.routeChangeLoad(val, oldVal);
                if (this.isHomePage) {
                    this.$nextTick(() => {
                        if (this.ageIsVerified) {
                            if (this.device.size < 1024) {
                                this.hideRoutChanger();
                            } else {
                                this.homeScroll();
                            }
                        }
                    });
                    this.hideHome = false;
                }
            },
            deep: true,
            immediate: true
        },
        $route: {
            immediate: true,

            handler() {
                // setTimeout(() => {
                // debounce(this.resizeEvent(), 700);
                this.updareScroll = true;
                //     // this.resizeEvent();
                // }, 2000);
                if (
                    (localStorage.getItem("verified") == null &&
                        this.$route.fullPath.includes(
                            "terms-and-conditions"
                        )) ||
                    this.$route.fullPath.includes("privacy")
                ) {
                    this.verified = true;
                } else {
                    this.verified = false;
                }
            }
        }
    },
    mounted() {
        this.mobileScroll();

        if (this.device.size > 1023) {
            let verifiedEvent = this.verifiedEvent();
            verifiedEvent.start();
        }
    },
    beforeDestroy() {
        this.$root.$off("age_verified");
    }
};
</script>

<style lang="scss" scoped>
$ease-out: cubic-bezier(0.14, 0.6, 0.3, 0.84);
.main-template {
    position: fixed;
    width: 100%;
    transition: 0.4s ease-in-out;
    // &.pointer {
    //     .background-image {
    //         cursor: pointer;
    //     }
    // }
    @media only screen and (max-width: 1023px) {
        position: relative;
    }
}

.scroll-container {
    will-change: transform;
    min-height: 100vh;
    color: #2c3e50;
    overflow: hidden;
    @media only screen and (max-width: 1023px) {
        will-change: unset;
        overflow: hidden;
    }
}

.data-scroll-sticky {
    position: absolute;
    height: 25000px;
    width: 100%;
    pointer-events: none;
    opacity: 0;
    top: 0;
    left: 0;
    @media only screen and (max-width: 1023px) {
        display: none;
    }
}

.page-pagination {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    position: fixed;
    top: 50%;
    right: 20px;
    transform: translateY(-50%);
    z-index: 2;

    &.lightMode {
        .list-item {
            .dot {
                background: rgba(0, 0, 0, 0.25);
            }
        }
    }

    .left {
        display: flex;
        align-items: center;
    }

    .list-item {
        @include center-children;
        cursor: pointer;
        transition: opacity 0.3s cubic-bezier(0.17, 0.67, 0.18, 0.99);
        @for $i from 1 through 15 {
            &:nth-child(#{$i}) {
                transition-delay: $i/7 + s;
            }
        }

        &.active,
        &:hover {
            .title {
                opacity: 1;
                animation: slide-down 0.6s $ease-out;
                transition: opacity 0.1ms;
            }

            .dot {
                background: #e5b676;
            }

            .slash {
                opacity: 1;
                background: #e5b676;
                transform: translateX(0);
                transition: all 0.6s $ease-out;
            }
        }

        .title-container {
            overflow-y: hidden;
        }

        .title {
            padding-right: 3px;
            opacity: 0;
            font-style: italic;
            font-weight: normal;
            color: #e5b676;
            display: block;
            position: relative;
            animation: slide-up 0.6s $ease-out;
            transition: opacity 0.1ms 0.5s;
        }

        .dot {
            @include circle(10);
            background: rgba(250, 250, 250, 0.2);
            transition: all 0.6s $ease-out;
            margin: 0 10px;
        }

        .slash {
            width: 14px;
            height: 1px;
            opacity: 0;
            transform: translateX(15px);
            transition: opacity 0.5s $ease-out, transform 0.3s 0.3s $ease-out;
        }
    }

    @media only screen and (max-width: 1024px) {
        display: none;
    }
}

.pagination-active,
.pagination-active {
    //transition: opacity .5s $ease-out;
    .list-item {
        opacity: 1;
    }
}

.pagination-enter,
.pagination-leave-to {
    .list-item {
        opacity: 0;
    }
}

@keyframes slide-up {
    0% {
        transform: translateY(0);
    }
    100% {
        transform: translateY(-100%);
    }
}

@keyframes slide-down {
    0% {
        transform: translateY(-100%);
    }
    100% {
        transform: translateY(0);
    }
}

.smart-components-target {
    min-height: 100vh;
    @media only screen and (max-width: 1024px) {
        min-height: unset;
    }
}
</style>
