import { mapActions, mapGetters } from "vuex";

export default {
    data() {
        return {
            clickedItemPosition: {}
        };
    },
    computed: {
        clickedElementStyles() {
            return {
                top: `${this.clickedItemPosition?.top}px`,
                width: `${this.clickedItemPosition?.width}px`,
                height: `${this.clickedItemPosition?.height}px`,
                right: `${this.clickedItemPosition?.right}px`,
                transform: `translate(${this.delta}px,-${this.delta}px)`,
                position: "absolute"
            };
        },
        ...mapGetters({
            scroll: "scroll/getScroll",
            animated: "scaleComponent/getAnimated",
            imageIsOpened: "scaleComponent/getOpened",
            device: "resize/geDevice"
        }),
        innerWidth() {
            return this.device.size;
        },
        delta() {
            let padding = 50;
            if (this.device.size < 1441) {
                padding = 30;
            }
            // if (this.device.size < 1365) {
            //     padding = 15
            // }
            if (this.device.size < 767) {
                padding = 16;
            }
            return padding;
        },
        clipPathDelta() {
            return this.device.size < 1024 ? 15 : 30;
        }
    },
    methods: {
        ...mapActions({
            setContent: "scaleComponent/setContent",
            setAnimated: "scaleComponent/setAnimated",
            setOpened: "scaleComponent/setOpened",
            setTransformY: "scaleComponent/setTransformY"
        }),
        setItemPosition(event) {
            let item = event.target;
            let { width, height, right, top } = item.getBoundingClientRect();
            this.clickedItemPosition = {
                top,
                right: this.innerWidth - right,
                width,
                height
            };
            this.createClickedItemClone(item);
            this.initClickedItemAnimation({
                right: this.innerWidth - right,
                top,
                width,
                height
            });
        },
        createClickedItemClone(item) {
            const setStylesOnElement = function(styles, element) {
                Object.assign(element.style, styles);
            };
            let itemClone = item.cloneNode(true);
            itemClone.setAttribute("data-ref", this.clickedTargetRef);
            setStylesOnElement(this.clickedElementStyles, itemClone);

            document.getElementById("scale-target").appendChild(itemClone);
        },
        initClickedItemAnimation({ right, top, width, height }) {
            let newWidth = 0;
            let aspectRatio = 0;
            let newHeight = 0;
            let transformX = 0;

            if (this.imageScaleHeightViewPort) {
                newHeight =
                    innerWidth < 1024 ? innerHeight : (3 / 2) * innerHeight;
                aspectRatio = width / height;

                newWidth = aspectRatio * newHeight;
                // let newWidth = innerWidth
            } else {
                newWidth = innerWidth - 2 * this.delta;
                aspectRatio = height / width;
                newHeight = newWidth * aspectRatio;
            }

            let scaleX = newWidth / width;
            let scaleY = newHeight / height;
            if (this.imageScaleHeightViewPort) {
                transformX =
                    -(
                        innerWidth -
                        (right + width + (newWidth - width) / 2) -
                        this.delta
                    ) -
                    (newWidth - innerWidth) / 2;
            } else {
                transformX = -(
                    innerWidth -
                    (right + width + (newWidth - width) / 2) -
                    2 * this.delta
                );
            }

            // let transformX = -50;

            // let transformX = -(innerWidth - (right + width + ((newWidth - width) / 2)) - 2 * this.delta);
            // let b = innerHeight - top - height
            // let transformY = -(top - ((newHeight - height) / 2) + (newHeight - innerHeight) / 2);
            let transformY =
                newHeight / 2 -
                top -
                height / 2 -
                this.delta +
                (innerHeight - newHeight) / 2;

            // this.setTransformY(transformY)

            if (this.device.size < 1024 && !this.imageScaleHeightViewPort) {
                aspectRatio = width / height;
                newHeight = this.device.innerHeight - 2 * this.delta;
                newWidth = aspectRatio * newHeight;
                transformX =
                    -(
                        innerWidth -
                        (right + width + (newWidth - width) / 2) -
                        this.delta
                    ) -
                    (newWidth - innerWidth) / 2;
                transformY = newHeight / 2 - top - height / 2;
                scaleX = newWidth / width;
                scaleY = newHeight / height;
            }

            this.gsap.set(document.querySelector(`#${this.clickedTargetRef}`), {
                opacity: 0,
                delay: 0.2
            });
            this.gsap.to(".scale-container img", {
                duration: 1,
                clipPath: "inset(0px)",
                force3D: false,
                x: transformX,
                y: transformY,
                scaleX: scaleX,
                scaleY: scaleY,
                // transform: `translate(${transformX}%,${transformY}px,0px) scale(${scaleX},${scaleY}) perspective(0)`,
                ease: this.CustomEase.create(
                    "custom",
                    "M0,0,C0.001,0,-0.001,0,0,0,0.798,0,0.205,1,1,1"
                ),
                onComplete: () => {
                    this.gsap.to(".scale-container", {
                        background: this.imageScaleHeightViewPort
                            ? "white"
                            : this.item?.color || "#212123",
                        pointerEvents: "auto"
                    });
                    if (this.imageScaleHeightViewPort) {
                        this.gsap.to(".scale-image-container", {
                            background: this.activeListItem?.color || "#212123",
                            overflow: "unset"
                        });
                    }
                    this.gsap.to(".scale-container  .content", {
                        opacity: 1,
                        onComplete: () => {
                            this.setAnimated(false);
                        }
                    });
                    this.gsap.to(".scale-container  .close-container", {
                        opacity: 1
                    });
                    this.$root.$emit("itemAnimationDone");
                }
            });
        },
        itemClick(event) {
            if (this.animated) return;
            this.hovered = false;
            this.setOpened(true);
            this.clicked = true;
            this.scroll?.stop();
            this.setAnimated(true);
            setTimeout(() => {
                this.setItemPosition(event);
            }, 300);
            this.setContent({
                name: this.item?.title || this.activeListItem?.title,
                position: this.item?.teaser || this.activeListItem?.teaser,
                place:
                    this.item?.description || this.activeListItem?.description,
                secondType:
                    this.item?.secondType || this.activeListItem?.secondType
            });
        },
        scaleDown($ev, immediate = false) {
            if (this.animated) return;
            this.setOpened(false);
            this.clicked = false;
            this.setAnimated(true);
            let scaledImage = document.querySelector(".scale-container img");
            let refAttribute = scaledImage.getAttribute("data-ref");

            this.gsap.to(".scale-container,.scale-image-container", {
                background: "transparent"
            });
            this.gsap.to(".scale-container  .close-container", {
                opacity: 0
            });

            this.gsap.to(".scale-container  .content", {
                opacity: 0,
                onComplete: () => {
                    this.gsap.to(scaledImage, {
                        duration: immediate ? 0 : 1,
                        force3D: false,
                        clipPath: this.scaleType
                            ? 0
                            : `inset(${this.clipPathDelta}px)`,
                        transform: `translate(${this.delta}px,-${this.delta}px) scale(1) perspective(0)`,
                        ease: this.CustomEase.create(
                            "custom",
                            "M0,0,C0.001,0,-0.001,0,0,0,0.798,0,0.205,1,1,1"
                        ),
                        onComplete: () => {
                            this.gsap.set(
                                document.querySelector(`#${refAttribute}`),
                                {
                                    opacity: 1,
                                    delay: immediate ? 0 : 0.1,
                                    onComplete: () => {
                                        setTimeout(() => {
                                            document
                                                .getElementById("scale-target")
                                                .removeChild(scaledImage);
                                            this.scroll?.start();
                                            this.setAnimated(false);
                                            this.gsap.to(".scale-container", {
                                                pointerEvents: "none"
                                            });
                                        }, 30);
                                    }
                                }
                            );
                        }
                    });
                }
            });
        },
        resize() {
            if (this.imageIsOpened && this.clicked) {
                const setStylesOnElement = function(styles, element) {
                    Object.assign(element.style, styles);
                };
                let item = document.querySelector(
                    `[data-ref=${this.clickedTargetRef}]`
                );
                let { width, height, right, top } = document
                    .querySelector(`#${this.clickedTargetRef}`)
                    .getBoundingClientRect();
                this.clickedItemPosition = {
                    top,
                    right: this.innerWidth - right,
                    width,
                    height
                };
                setStylesOnElement(this.clickedElementStyles, item);
                this.clickedItemPosition = {
                    top,
                    right: this.innerWidth - right,
                    width,
                    height
                };

                this.initClickedItemAnimation({
                    right: this.innerWidth - right,
                    top,
                    width,
                    height
                });
            }
        },
        resizeHandler() {
            window.addEventListener("resize", this.resize);
        }
    },
    mounted() {
        this.$nextTick(() => {
            this.resizeHandler();
        });
    }
};
