<template>
    <mouse-changer :type="mouseTypes.ImageDown">
        <div @click="scaleDown" id="scale-container" class="scale-container">
            <div
                class="scale-image-container"
                :class="{ anim: anim }"
                id="scale-target"
            ></div>
            <!-- <h1 style="z-index: 999999; color: white;">
                {{ getRotateDevice }} {{ rotateX }}
            </h1> -->
            <div class="content">
                <header-title
                    v-if="!$route.fullPath.includes('brands')"
                    :name="scaleContent.name"
                    text-color="white"
                />
                <h2 class="place" v-html="scaleContent.place"></h2>
                <h2 class="position">{{ scaleContent.position }}</h2>
            </div>
            <div class="close-container">
                <svg
                    class="icon"
                    width="15.556"
                    height="15.556"
                    viewBox="0 0 15.556 15.556"
                >
                    <g
                        id="Group_55"
                        data-name="Group 55"
                        transform="translate(7.778 -4.95) rotate(45)"
                    >
                        <g
                            id="Group_74"
                            data-name="Group 74"
                            transform="translate(0 0)"
                        >
                            <line
                                id="Line_5"
                                data-name="Line 5"
                                x2="18"
                                transform="translate(0 9)"
                                fill="none"
                                stroke="#E5B676"
                                stroke-linecap="round"
                                stroke-width="2"
                            />
                            <line
                                id="Line_15"
                                data-name="Line 15"
                                y2="18"
                                transform="translate(9)"
                                fill="none"
                                stroke="#E5B676"
                                stroke-linecap="round"
                                stroke-width="2"
                            />
                        </g>
                    </g>
                </svg>
            </div>
        </div>
    </mouse-changer>
</template>

<script>
import ImageScale from "@/mixins/ImageScale";
import { mapGetters } from "vuex";
import HeaderTitle from "@/components/custom/HeaderTitle";
import MouseChanger from "@/components/custom/MouseChanger";

export default {
    computed: {
        ...mapGetters({
            scaleContent: "scaleComponent/getContent",
            transformY: "scaleComponent/getTransformY",
            animated: "scaleComponent/getAnimated",
            device: "resize/geDevice",
            opened: "scaleComponent/getOpened"
        }),
        // getRotateDevice() {
        //     return this.rotateX;
        // },
        scaleType() {
            return this.scaleContent?.secondType;
        }
    },
    data() {
        return {
            anim: false
            // rotateX: 0
        };
    },
    mixins: [ImageScale],
    components: {
        HeaderTitle,
        MouseChanger
    },
    watch: {
        "$route.params": {
            handler: function() {
                if (this.opened) this.scaleDown({}, true);
            },
            deep: true,
            immediate: true
        },
        opened: function() {
            //dd
            if (this.opened) {
                setTimeout(() => {
                    this.anim = true;
                    const image = document.querySelector(
                        ".scale-container img"
                    );

                    if (
                        /Android|webOS|iPhone|iPad|iPod|BlackBerry/i.test(
                            navigator.userAgent
                        )
                    ) {
                        // window.addEventListener("deviceorientation", function(
                        //     event
                        // ) {
                        //     event.stopPropagation();
                        //     event = event || window.event;
                        //     this.rotateX = parseInt(event.beta * 2);
                        // });
                        window.ondevicemotion = function(event) {
                            this.rotateX = event.accelerationIncludingGravity.x;
                            image.style.right = `${event
                                .accelerationIncludingGravity.x * 12}px`;
                        };
                    }
                }, 1000);
            }
        }
    },
    methods: {
        mousemove(ev) {
            if (this.animated || this.device.size < 1025) return;
            const image = document.querySelector(".scale-container img");
            let itemHeight = image?.getBoundingClientRect()?.height;

            if (!image || itemHeight < innerHeight - 2 * this.delta) return;

            const clamp = (num, min, max) => {
                return num < min ? min : num > max ? max : num;
            };
            let maxDelta = (itemHeight - innerHeight) / 2;
            let a = innerHeight / 2 - ev.clientY;
            let del = (a * (innerHeight / 2 + maxDelta)) / (innerHeight / 2);
            let itemRealHeight = this.gsap.getProperty(image, "height");
            let itemTop = this.gsap.getProperty(image, "top");
            let itemBottom = innerHeight - itemTop - itemRealHeight;
            let topMaxDelta = (itemHeight - itemRealHeight) / 2 - itemTop;
            let bottomMaxDelta = -(
                (itemHeight - itemRealHeight) / 2 -
                itemBottom +
                2 * this.delta
            );
            if (window.innerWidth < 768) {
                this.gsap.to(image, {
                    x: clamp(del, bottomMaxDelta, topMaxDelta)
                });
            } else {
                this.gsap.to(image, {
                    y: clamp(del, bottomMaxDelta, topMaxDelta)
                });
            }
        },
        mouseMoveHandler() {
            if (this.device.size < 1025) return;
            window.addEventListener("mousemove", this.mousemove, false);
        }
    },
    created() {
        this.mouseMoveHandler();
    }
};
</script>

<style lang="scss" scoped>
.scale-container /deep/ {
    position: fixed;
    left: 0;
    top: 0;
    height: 100vh;
    width: 100%;
    z-index: 10;
    pointer-events: none;
    overflow: hidden;
    .anim {
        img {
        }
    }
    @media only screen and (max-width: 1023px) {
        z-index: 99;
    }

    .scale-image-container {
        position: relative;
        height: calc(100vh - 100px);
        width: calc(100% - 100px);
        overflow: hidden;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        @media (max-width: 767px) {
            .image {
                transition: 0.6s;
            }
        }
        @media only screen and (max-width: 1441px) and (min-width: 768px) {
            height: calc(100vh - 60px);
            width: calc(100% - 60px);
        }
        //@media only screen and (max-width: 1365px) and (min-width: 768px) {
        //    height: calc(100vh - 30px);
        //    width: calc(100% - 30px);
        //}
        @media only screen and (max-width: 767px) {
            height: calc(100vh - 32px);
            width: calc(100% - 32px);
            //height: 100%;
            //width: 100%;
        }
    }

    .image {
        clip-path: inset(30px);
        transform-origin: center;
        @media only screen and (max-width: 1023px) {
            clip-path: inset(15px);
        }
    }

    .content {
        position: absolute;
        left: 120px;
        bottom: 137px;
        opacity: 0;
        text-align: left;
        z-index: 1;
        @media only screen and (max-width: 1650px) and (min-width: 1440px) {
            left: 90px;
            bottom: 90px;
        }
        @media only screen and (max-width: 1439px) and (min-width: 1024px) {
            left: 128px;
            bottom: 90px;
        }

        @media only screen and (max-width: 1023px) and (min-width: 768px) {
            left: 90px;
            bottom: 90px;
        }

        @media only screen and (max-width: 767px) {
            left: 50px;
            bottom: 70px;
        }

        .header-title {
            // display: none;
            font-weight: normal;
            line-height: unset !important;
            @media only screen and (max-width: 1650px) and (min-width: 1440px) {
                font-size: 55px;
            }
            @media only screen and (max-width: 1439px) and (min-width: 768px) {
                font-size: 55px;
            }
            @media only screen and (max-width: 767px) {
                font-size: 34px;
            }
        }

        .place {
            font-size: 21px;
            color: #e5b676;
            margin-top: 22px;
            font-weight: normal;
            max-width: 80%;
            @media only screen and (max-width: 1365px) and (min-width: 768px) {
                margin-top: 30px;
            }
            @media only screen and (max-width: 768px) {
                margin-top: 20px;
                max-height: 200px;
                overflow-y: auto;
            }
        }

        .position {
            font-size: 16px;
            color: white;
            font-weight: normal;
            margin-top: 10px;
            max-width: 600px;
        }
    }

    .close-container {
        @include circle(60);
        border: 1px solid rgba(193, 148, 50, 0.3);
        display: none;
        right: 50px;
        top: 50px;
        z-index: 3;
        position: absolute;
        align-items: center;
        justify-content: center;
        opacity: 0;
        pointer-events: none;
        @media only screen and (max-width: 1023px) {
            display: flex;
        }
    }
}
</style>
