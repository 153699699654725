<template>
    <div class="history-scale-slider">
        <div class="guide" v-if="guide">
            <img src="@/assets/images/cursor.svg" />
        </div>
        <div @click.self="closeSlider" class="slider-list">
            <div class="slider-hide top" @click.self="closeSlider"></div>
            <div class="slider-hide bottom" @click.self="closeSlider"></div>
            <div @click="closeSlider" class="close-container">
                <svg
                    class="icon"
                    width="15.556"
                    height="15.556"
                    viewBox="0 0 15.556 15.556"
                >
                    <g
                        id="Group_55"
                        data-name="Group 55"
                        transform="translate(7.778 -4.95) rotate(45)"
                    >
                        <g
                            id="Group_74"
                            data-name="Group 74"
                            transform="translate(0 0)"
                        >
                            <line
                                id="Line_5"
                                data-name="Line 5"
                                x2="18"
                                transform="translate(0 9)"
                                fill="none"
                                stroke="#E5B676"
                                stroke-linecap="round"
                                stroke-width="2"
                            />
                            <line
                                id="Line_15"
                                data-name="Line 15"
                                y2="18"
                                transform="translate(9)"
                                fill="none"
                                stroke="#E5B676"
                                stroke-linecap="round"
                                stroke-width="2"
                            />
                        </g>
                    </g>
                </svg>
            </div>
            <mouse-changer
                :type="mouseTypes.Slider"
                v-for="(item, index) in slides"
                :mouseLeaveCallback="setMouseType"
                :key="index"
                :callBackType="mouseTypes.ImageDown"
            >
                <div
                    :class="{
                        active: activeSlideIndex === index,
                        empty: item.description === ''
                    }"
                    @click="changeSlide($event)"
                    class="slider-item"
                    @mousemove="mouseMoveEv($event)"
                    @mouseleave="mouseLeave"
                >
                    <div class="image-container">
                        <img :src="item.path" class="image" alt="" />
                    </div>
                    <div class="content-container">
                        <div class="content">
                            <header-title
                                :name="item.title"
                                text-color="white"
                            />
                            <span class="description colored">{{
                                item.teaser
                            }}</span>
                            <span
                                class="description"
                                v-html="item.description"
                            ></span>
                        </div>
                    </div>
                </div>
            </mouse-changer>
        </div>
    </div>
</template>

<script>
import HeaderTitle from "@/components/custom/HeaderTitle";
import { mapActions, mapGetters } from "vuex";
import MouseChanger from "@/components/custom/MouseChanger";

export default {
    props: {
        slides: {
            type: Array,
            default: () => []
        },
        activeSlideIndex: {
            type: Number,
            default: 0
        },
        changeSlideIndex: {
            type: Function,
            default: () => {}
        }
    },
    data() {
        return {
            show: true,
            guide: true,
            mouse: {
                clientX: 0
            },
            swipeCounter: 0
        };
    },
    mounted() {
        this.touchFunction();
    },
    components: {
        HeaderTitle,
        MouseChanger
    },
    computed: {
        ...mapGetters({
            getHistoryOpened: "scaleComponent/getHistoryOpened"
        }),
        activeSlide() {
            return this.slides?.find(slide => slide.active === true);
        },
        isLastSlide() {
            return this.activeSlideIndex == this.slides.length - 1;
        }
    },
    methods: {
        ...mapActions({
            setHistoryOpened: "scaleComponent/setHistoryOpened",
            setMouseType: "mouseCursor/setMouseType",
            setActiveIndex: "mouseCursor/setActiveIndex"
        }),
        touchFunction() {
            document
                .querySelector(".slider-list")
                .addEventListener("touchstart", e => {
                    this.mouse.clientX = e.changedTouches[0].clientX;
                });
            // document;
            // .querySelector(".slider-list")
            // .addEventListener("touchmove", e => {
            //     console.log(e.offsetX);
            //     this.mouseX = e.offsetX;
            // });
            document
                .querySelector(".slider-list")
                .addEventListener("touchend", () => {
                    // if (this.mouse.clientX < e.changedTouches[0].clientX) {

                    // } else {

                    // }
                    this.clickEventHandler(this.mouse);
                });
        },
        changeSlide(e) {
            if (window.innerWidth > 1024) {
                this.clickEventHandler(e);
            }
        },
        mouseLeave() {
            if (window.innerWidth < 1024) {
                return;
            }
            if (
                document.querySelector(".custom-cursor") !== undefined ||
                (document.querySelector(".custom-cursor") !== null &&
                    window.innerWidth > 1024)
            ) {
                // document.querySelector(".custom-cursor").style.opacity = "1";
                this.setMouseType(this.mouseTypes.Slider);
            }
        },
        mouseMoveEv(e) {
            if (window.innerWidth < 1024) {
                this.touchFunction();
                return;
            }
            if (e.clientX > window.innerWidth / 2 && this.isLastSlide) {
                this.setMouseType(this.mouseTypes.Default);
            } else {
                this.setMouseType(this.mouseTypes.Slider);
            }
        },
        closeSlider() {
            this.show = false;
            this.setHistoryOpened(false);
            this.gsap.to(".history-scale-slider .slider-hide", {
                opacity: 0
            });
            this.gsap.to(".history-scale-slider", {
                opacity: 0,
                duration: 0.6,
                onComplete: () => {
                    this.gsap.set(".history-scale-slider", {
                        display: "none",
                        background: "transparent"
                    });
                    this.gsap.set(".history-scale-slider .image-container", {
                        top: 0,
                        right: 0,
                        width: 0,
                        transform: "unset"
                    });
                    this.gsap.set(".history-scale-slider .content-container", {
                        opacity: 0
                    });
                    this.gsap.set(".history-scale-slider .close-container", {
                        opacity: 0
                    });
                    this.gsap.set(".history-scale-slider .slider-item", {
                        clearProps: "all"
                    });
                    setTimeout(() => {
                        this.setMouseType(this.mouseTypes.Default);
                    }, 500);
                }
            });
        },
        changeSlider(direction) {
            let slides = document.querySelectorAll(
                ".history-scale-slider .slider-item"
            );
            let slideContents = document.querySelectorAll(
                ".history-scale-slider .slider-item .content-container"
            );
            const clamp = (num, min, max) =>
                num <= min ? min : num >= max ? max : num;
            let newActiveSlideIndex = clamp(
                this.activeSlideIndex + direction,
                0,
                this.slides.length - 1
            );
            if (this.activeSlideIndex === newActiveSlideIndex) return;

            this.gsap.to(slides[this.activeSlideIndex], {
                opacity: 0,
                scale: 0.95,
                duration: 0.4,
                delay: 0.3
            });
            this.gsap.to(slideContents[this.activeSlideIndex], {
                opacity: 0,
                duration: 0.4
            });
            this.gsap.to(slides[newActiveSlideIndex], {
                opacity: 1,
                scale: 1,
                delay: 0.7,
                duration: 0.6,
                onComplete: () => {
                    this.changeSlideIndex(newActiveSlideIndex);
                }
            });
            this.gsap.to(slideContents[newActiveSlideIndex], {
                opacity: 1,
                duration: 0.4,
                delay: 1
            });
        },
        clickEventHandler(ev) {
            let direction = ev.clientX > innerWidth / 2 ? 1 : -1;

            this.changeSlider(direction);
            this.guide = false;
        }
    },
    watch: {
        "$route.params": {
            handler: function() {
                if (this.getHistoryOpened) this.closeSlider({}, true);
            },
            deep: true,
            immediate: true
        }
        // activeSlideIndex(val) {
        //     this.setActiveIndex(this.isLastSlide);
        // }
    }
};
</script>

<style lang="scss" scoped>
$ease-out: cubic-bezier(0.01, 0.26, 0.25, 1);
img {
    pointer-events: none;
    user-select: none;
}

@keyframes guide-anim {
    from {
        transform: scale(0.8);
        opacity: 0;
    }
    to {
        transform: scale(1);
        opacity: 1;
    }
}

.history-scale-slider /deep/ {
    @include center-children;
    background: transparent;
    position: absolute;
    z-index: 9;
    left: 0;
    top: 0;
    width: 100%;
    height: 100vh;
    display: none;
    .guide {
        pointer-events: none;
        display: none;
        justify-content: center;
        align-items: center;
        position: absolute;
        z-index: 9999;
        width: 80px;
        height: 80px;
        animation-delay: 2s;
        opacity: 0;
        top: 162px;
        right: 40px;
        animation-iteration-count: infinite;
        animation-direction: alternate;
        // transition: 0.6s;
        animation-name: guide-anim;
        animation-duration: 1s;
        @media (max-width: 767px) {
            display: flex;
        }
    }
    // pointer-events: none;
    overflow: hidden;
    @media only screen and (max-width: 1024px) {
        position: fixed;
        z-index: 99;
    }

    .close-container {
        @include circle(60);
        border: 1px solid rgba(193, 148, 50, 0.3);
        display: none;
        right: 50px;
        top: 50px;
        z-index: 3;
        position: absolute;
        align-items: center;
        justify-content: center;
        opacity: 0;
        @media only screen and (max-width: 1023px) {
            display: flex;
        }
    }

    .slider-hide {
        position: absolute;
        width: 100%;
        height: 75px;
        background: #0a141c;
        z-index: 1;
        // pointer-events: none;
        opacity: 0;
        @media only screen and (max-width: 1599px) {
            height: 30px;
        }
        @media only screen and (max-width: 767px) {
            height: 20px;
        }

        &.top {
            top: 0;
        }

        &.bottom {
            bottom: 0;
        }
    }

    .slider-list {
        position: relative;
        height: 100%;
        width: 100%;
        @media (max-width: 1023px) {
            width: calc(100% - 30px);
            overflow: hidden;
        }

        .slider-item {
            position: absolute;
            opacity: 0;
            height: calc(100% - 150px);
            width: calc(100% - 150px);
            top: 75px;
            left: 75px;
            transform: scale(0.95);
            &.empty {
                .content-container {
                    background: none;
                    display: flex;
                    flex-direction: column;
                    justify-content: flex-end;
                    .content {
                        top: unset;
                        .description {
                            font-size: 24px;
                        }
                    }
                }
            }
            @media only screen and (max-width: 1441px) and (min-width: 1024px) {
                height: calc(100% - 30px);
                width: calc(100% - 60px);
                top: 30px;
                left: 30px;
                overflow: hidden;
            }
            @media only screen and (max-width: 1023px) {
                height: 100vh;
                width: 100%;
                top: 0;
                left: 0;
            }

            &.active {
                opacity: 1;
                transform: scale(1);
            }

            .image-container {
                position: absolute;
                width: 100%;
                height: 100%;
                top: 0;
                overflow: hidden;
                transform-origin: center;
                // pointer-events: none;

                .image {
                    //height: 110%;
                    //top: -5%;
                    position: relative;
                    width: 100%;
                    @media only screen and (max-width: 1023px) {
                        height: 100%;
                        width: auto;
                    }
                }
            }

            .content-container {
                position: absolute;
                width: calc(50% - 75px);
                height: 100%;
                bottom: 0;
                background: rgba(10, 20, 28, 0.75);
                opacity: 0;
                // pointer-events: none;
                @media only screen and (max-width: 1440px) and (min-width: 1024px) {
                    padding: 20px 20px 20px 60px;
                    width: 50% !important;
                }
                @media only screen and (max-width: 1023px) {
                    position: absolute;
                    height: fit-content;
                    width: 100% !important;
                    top: unset;
                    padding: 20px;
                    bottom: 30px;
                }
                @media only screen and (max-width: 767px) {
                    width: 100% !important;
                    bottom: 20px;
                }

                .content {
                    position: absolute;
                    left: 120px;
                    bottom: 78px;
                    z-index: 1;
                    max-width: 600px;
                    text-align: left;

                    @media only screen and (max-width: 1650px) and (min-width: 1441px) {
                        left: 20px;
                        .header-title {
                            font-size: 69px;
                        }
                    }
                    @media only screen and (max-width: 1440px) and (min-width: 1024px) {
                        left: 20px;
                        bottom: 78px;
                    }
                    @media (min-width: 1023px) {
                        position: relative;
                        top: 50%;
                        /* width: 100%; */
                        left: 45%;
                        transform: translate(-50%, -50%);
                    }
                    @media only screen and (max-width: 1023px) {
                        position: relative;
                        left: 0;
                        bottom: 0;
                        width: 100%;
                    }

                    .description {
                        font-size: 16px;
                        color: white;
                        display: block;
                        margin-top: 30px;
                        text-align: left;

                        @media only screen and (max-width: 767px) {
                            font-size: 14px;
                            margin-top: 15px;
                            height: 300px;
                            overflow: auto;
                            padding-right: 15px;
                            &.colored {
                                height: unset;
                                overflow: unset;
                            }
                        }

                        &.colored {
                            color: #e5b676;
                            margin-top: 20px;
                        }
                    }
                }
            }
        }
    }
}
</style>
