var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"main-template"},[(!_vm.ageIsVerified)?_c('age-verification',{on:{"onVerify":function($event){_vm.verified = true}}}):[_c('div',{staticClass:"scroll-container",attrs:{"data-scroll-container":"","id":"image-load-container"}},[_c('div',{staticClass:"scroll-section"},[_c('div',{staticClass:"data-scroll-sticky",attrs:{"id":"data-scroll-header-target"}}),(_vm.routeLoad)?_c('Header',{attrs:{"components":_vm.components,"showResizedHeader":_vm.showResizedHeader,"data-scroll":"","data-scroll-sticky":"","data-scroll-target":"#data-scroll-header-target"}}):_vm._e(),((_vm.isHomePage || !_vm.pageIsLoaded) && !_vm.hideHome)?_c('home-page'):_vm._e(),_c('div',{staticClass:"smart-components-target",on:{"click":function($event){return _vm.scrollToClick(_vm.components[1].paginationTitle)}}},_vm._l((_vm.components),function(component){return _c(component.component,{key:component.id,tag:"component",attrs:{"id":component.paginationTitle,"data":component.data,"name":component.paginationTitle,"lightMode":_vm.lightMode,"config":component.config}})}),1),(!_vm.isHomePage)?_c('Footer',{attrs:{"darkFooter":!_vm.lightMode && !_vm.isWines}}):_vm._e()],1)]),_c('scale-component'),_c('portal-target',{attrs:{"name":"destination"}}),_c('transition',{attrs:{"name":"pagination"}},[(_vm.showPagePagination && !_vm.isHomePage && _vm.pageIsLoaded)?_c('div',{staticClass:"page-pagination",class:{ lightMode: _vm.lightMode }},_vm._l((_vm.components),function(item,index){return _c('div',{key:index,staticClass:"list-item",class:{
                        active: item.paginationTitle === _vm.activeComponent
                    },on:{"click":function($event){return _vm.scrollTo(item.paginationTitle)}}},[(
                            item.config.header.full_list_title !== null &&
                                item.config.useHeader === '1'
                        )?_c('div',{staticClass:"title-container"},[_c('span',{staticClass:"title"},[_vm._v(_vm._s(item.paginationTitle))])]):_vm._e(),(
                            item.config.header.full_list_title !== null &&
                                item.config.useHeader === '1'
                        )?_c('div',{staticClass:"left"},[_c('div',{staticClass:"dot"}),_c('div',{staticClass:"slash"})]):_vm._e()])}),0):_vm._e()]),_c('video-player',{attrs:{"videoPath":_vm.videoPath}}),(_vm.showCursor)?_c('custom-cursor'):_vm._e()]],2)}
var staticRenderFns = []

export { render, staticRenderFns }