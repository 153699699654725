<template>
    <div
        :class="{
            dark: darkFooter,
            'where-to-find-footer': whereToFind,
            'footer--wines': isWines
        }"
        class="footer"
    >
        <div v-observer class="footer-contain">
            <div class="up">
                <h2 class="header">{{ social.title }}</h2>
                <span class="mail">{{ social.mail }}</span>
            </div>
            <div class="footer-down">
                <div class="contact-us">
                    <div class="social-media">
                        <a :href="social.Facebook" class="item link"
                            >Facebook /</a
                        >
                        <a :href="social.Instagram" class="item link">
                            Instagram /</a
                        >
                        <a :href="social.Youtube" class="item link">
                            YourTube</a
                        >
                    </div>
                </div>
                <div class="terms">
                    <a
                        v-for="(item, index) in footerMenu"
                        :href="item.url_slug"
                        :key="index"
                        class="link"
                        >{{ item.title }}
                        <span v-if="index !== footerMenu.length - 1">/</span></a
                    >
                </div>
                <div class="right">
                    <div class="share" v-if="share">
                        <span class="title" @click="showShareList">{{
                            social.share_text
                        }}</span>
                        <img
                            src="../assets/images/share.svg"
                            class="icon"
                            alt="share icon"
                        />
                    </div>
                    <div class="share-box" key="share-options" v-else>
                        <template v-for="(icon, index) in getShareLinks">
                            <div
                                class="share-icon"
                                v-html="icon.title"
                                :key="index"
                                @click.stop="openSharePopup(icon.url)"
                                target="_blank"
                                :href="icon.url"
                            ></div>
                        </template>
                    </div>
                </div>
            </div>
            <div class="cop-container">
                <span class="copy-right description">{{
                    social.copyright_text
                }}</span>
                <span class="description"
                    >Designed & Development by
                    <a
                        target="_blank"
                        class="connect"
                        href="https://www.connect.ge/en/"
                        >CONNECT</a
                    ></span
                >
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
    props: {
        darkFooter: {
            type: Boolean,
            default: true
        }
    },
    data() {
        return {
            share: true
        };
    },
    computed: {
        ...mapGetters({
            menu: "menu/getMenu",
            indexData: "menu/getIndexData",
            lang: "language/getLang",
            scroll: "scroll/getScroll"
        }),
        footerMenu() {
            return this.menu
                ?.filter(item => item.menu_type === "footer")
                ?.map(item => ({
                    url_slug: item?.url_slug,
                    title: item?.titles?.[this.lang]?.title
                }));
        },
        social() {
            return this.indexData?.widgets?.footer || {};
        },
        getWidgetData() {
            return this.$store?.state?.menu?.indexData?.widgets;
        },
        getShareLinks() {
            let socialList = [];

            let socialSvgs = this.getWidgetData?.shareicons;
            let uri = encodeURI(
                window.location.protocol +
                    "//" +
                    window.location.host +
                    "" +
                    this.$route.path +
                    window.location.search
            );

            if (!socialSvgs) return socialList;
            for (let i in socialSvgs) {
                socialList.push({
                    title: socialSvgs[i].title,
                    url: socialSvgs[i].url + uri
                });
            }

            return socialList;
        },
        whereToFind() {
            return this.$route.path.includes("where-to-find");
        },
        isWines() {
            return (
                (this.$route.params.lang === "wines" &&
                    this.$route.params.path1?.length) ||
                (this.$route.params.path1 === "wines" &&
                    this.$route.params.path2?.length)
            );
        }
    },
    methods: {
        showShareList() {
            this.share = !this.share;
            setTimeout(() => {
                this.share = true;
            }, 10000);
        },
        openSharePopup(url) {
            let winWidth = 650;
            let winHeight = 350;
            let winTop = 200;
            let winLeft = screen.width / 2 - winWidth / 2;
            window.open(
                url,
                "sharer",
                "top=" +
                    winTop +
                    ",left=" +
                    winLeft +
                    ",toolbar=0,status=0,width=" +
                    winWidth +
                    ",height=" +
                    winHeight
            );
        }
    },
    created() {}
};
</script>

<style lang="scss" scoped>
$ease-out: cubic-bezier(0.15, 0.16, 0.37, 1);
.share-box {
    display: flex;
    align-items: center;

    .share-icon {
        margin: 0 10px;
        color: #0a141c;
        transition: 0.3s ease;
        cursor: pointer;

        &:hover {
            color: #e5b676;
            transition: 0.3s ease;
        }
    }
}

.share {
    cursor: pointer;
}

.footer {
    background: white;
    position: relative;
    transition: all 0.4s $ease-out;
    padding: 144px 195px 55px;
    will-change: transform;

    &.where-to-find-footer {
        padding-top: 55px !important;
    }

    @media only screen and (max-width: 1650px) and (min-width: 1440px) {
        padding: 144px 165px 55px;
    }
    @media only screen and (max-width: 1439px) and (min-width: 1366px) {
        padding: 144px 128px 55px;
    }
    @media only screen and (max-width: 1365px) and (min-width: 768px) {
        padding: 159px 47px 55px;
    }
    @media only screen and (max-width: 767px) {
        padding: 55px 16px 55px;
    }

    &.dark {
        background: #0a141c;

        .share-box {
            .share-icon {
                color: #fff;

                &:hover {
                    color: #e5b676;
                    transition: 0.3s ease;
                }
            }
        }

        .mail {
            color: white;
        }
    }

    &--wines,
    &--wines.dark {
        background-color: #f2f2f2;
        padding-top: 39px;
        padding-bottom: 42px;
    }

    .footer-contain {
        transform: translateY(70px);
        transition: transform 0.6s $ease-out;
        will-change: transform;

        &.observed {
            transform: translateY(0px);
        }
    }

    .header {
        color: #e5b676;
        font-size: 16px;
        text-align: left;
        font-weight: normal;
        will-change: transform;
        font-family: "Noto-Sans", "Noto-Sans-georgian";
    }

    .mail {
        font-size: 21px;
        color: #0a141c;
        font-family: "Noto-Sans", "Noto-Sans-georgian";
        text-decoration: underline;
        text-decoration-color: #e5b676;
        text-decoration-thickness: 2px;
        font-weight: normal;
        text-align: left;
        display: block;
        margin-top: 5px;
        transition: all 0.4s $ease-out;
        will-change: transform;
    }

    .link {
        font-size: 16px;
        color: #9a9a9a;
        cursor: pointer;
        text-decoration: none;
        word-break: break-all;
        display: block;
        will-change: transform;
        font-family: "Noto-Sans", "Noto-Sans-georgian";
    }

    .footer-down {
        @include space-between;
        margin-top: 25px;
        @media only screen and (max-width: 767px) {
            align-items: flex-start;
        }

        .description {
            color: rgba(154, 154, 154, 0.5);
            font-size: 13px;
            margin-top: 13px;
            display: block;
            will-change: transform;
            font-family: "Noto-Sans", "Noto-Sans-georgian";
        }

        .social-media {
            text-align: left;
            display: flex;
            align-items: center;
            flex-wrap: wrap;
            will-change: transform;
            .item {
                cursor: pointer;
                transition: all 0.5s cubic-bezier(0, 0.41, 0.02, 1);
                &:hover {
                    color: #e5b676;
                }
            }
        }

        .terms {
            display: flex;
            align-items: center;
            flex-wrap: wrap;

            .link {
                text-decoration: underline;
                will-change: transform;
                margin-left: 5px;
                cursor: pointer;
                transition: all 0.5s cubic-bezier(0, 0.41, 0.02, 1);
                &:hover {
                    color: #e5b676;
                }
                &:first-child {
                    margin-left: 0;
                }
            }
        }

        .right {
            display: flex;
            justify-content: flex-end;
            flex-direction: column;
            font-size: 16px;

            .share {
                display: flex;
                align-items: center;

                .title {
                    color: #e5b676;
                    margin-right: 11px;
                    will-change: transform;
                    font-family: "Noto-Sans", "Noto-Sans-georgian";
                }
            }
        }
    }

    .cop-container {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-top: 14px;

        .description {
            color: rgba(154, 154, 154, 1);
            font-size: 13px;
            display: block;
            will-change: transform;
            font-family: "Noto-Sans", "Noto-Sans-georgian";
            opacity: 0.5;
        }

        .connect {
            color: #e5b676;
            text-decoration: underline;
            font-family: "Noto-Sans", "Noto-Sans-georgian";
        }
    }

    @media only screen and (max-width: 1650px) and (min-width: 1440px) {
        .footer-down {
            justify-content: unset;

            .right {
                position: absolute;
                right: 0;
            }

            .terms {
                margin-left: 259px;
            }
        }
    }
    @media only screen and (max-width: 1439px) and (min-width: 1366px) {
        .footer-down {
            justify-content: unset;

            .right {
                position: absolute;
                right: 0;
            }

            .terms {
                margin-left: 30px;
            }
        }
    }
    @media only screen and (max-width: 1365px) and (min-width: 768px) {
        .footer-down {
            justify-content: unset;

            .right {
                position: absolute;
                right: 0;
            }

            .terms {
                margin-left: 18px;
            }
        }
    }
    @media only screen and (max-width: 1440px) and (min-width: 1024px) {
        .link {
            font-size: 14px;
        }
        .footer-down {
            .right {
                font-size: 14px;
            }
        }
    }
    @media only screen and (max-width: 1023px) and (min-width: 768px) {
        .link {
            font-size: 13px;
            margin-left: 0 !important;
        }
        .footer-down {
            .right {
                font-size: 13px;
            }
        }
    }
    @media only screen and (max-width: 1023px) {
        .copy-right {
            text-align: left;
        }
    }
    @media only screen and (max-width: 767px) {
        .link {
            font-size: 13px;
        }
        .mail {
            font-size: 21px;
        }
        .header {
            font-size: 16px;
            margin-top: 5px;
        }
        .footer-down {
            flex-direction: column;

            .terms {
                margin: 11px 0;
            }

            .terms,
            .description {
                text-align: left;
            }

            .right {
                font-size: 13px;
            }
        }

        .cop-container {
            flex-direction: column-reverse;
            align-items: flex-start;

            .description {
                margin-top: 20px;
            }
        }
    }
}
</style>
