<template>
    <div class="lang-container">
        <span
            v-for="(item, index) in languages"
            :key="index"
            @click="changeLanguage(item)"
            class="lang-item"
            :class="{ active: activeLanguage.id === item.id }"
        >
            {{ item.longTitle }} /
        </span>
    </div>
</template>

<script>
import { SUPPORTED_LANGUAGES } from "@/constants/trans";
import { mapGetters } from "vuex";
import gsap from "gsap";

export default {
    computed: {
        ...mapGetters({
            lang: "language/getLang"
        }),
        languages() {
            let arr = [];
            SUPPORTED_LANGUAGES.forEach(item => {
                if (item.id === this.activeLanguage.id) {
                    arr.push(item);
                }
            });
            SUPPORTED_LANGUAGES.forEach(item => {
                arr.push(item);
            });
            let uniq = [...new Set(arr)];
            return uniq.filter(el => el.id !== "ru");
        },
        activeLanguage() {
            return (
                SUPPORTED_LANGUAGES?.find(item => item.id === this.lang) || {}
            );
        }
    },
    mounted() {},
    methods: {
        changeLanguage(item) {
            gsap.to(".rout-changer", {
                opacity: 1,
                pointerEvents: "auto",
                duration: 0.2,
                onComplete: () => {
                    let withoutLanguageParams =
                        this.lang !== this.$route?.params?.lang;
                    let fromPath = withoutLanguageParams
                        ? this.$route?.params?.lang
                        : this.$route?.params?.path1;

                    !fromPath
                        ? this.$router.push({ path: `/${item.id}` })
                        : this.$router.push({ params: { lang: item.id } });
                }
            });
        }
    }
};
</script>

<style lang="scss" scoped>
$ease-out: cubic-bezier(0, 0.41, 0.02, 1);

.lang-container {
    display: flex;
    grid-gap: 5px;
    justify-self: flex-start;

    .lang-item {
        font-size: 16px;
        font-style: italic;
        color: #9a9a9a;
        letter-spacing: 1.2px;
        cursor: pointer;
        transition: all 0.5s $ease-out;
        @media only screen and (max-width: 1024px) {
            font-size: 14px;
        }

        &.active,
        &:hover {
            color: #e5b676;
        }
    }
}
</style>
