<template>
    <div class="root">
        <Menu
            v-if="showMenu"
            @routeUpdateFromMenu="routeUpdateFromMenu = true"
            :menu="menu"
        />
        <template v-if="pagesData.length">
            <main-template :style="{ background: pageColor }" />
        </template>
        <div class="rout-changer">
            <li class="chasing-dots">
                <div class="dot1"></div>
                <div class="dot2"></div>
            </li>
        </div>
        <div class="paging-rout-changer"></div>
    </div>
</template>

<script>
import MainTemplate from "@/views/MainTemplate";
import Menu from "@/components/Menu";
import { mapActions, mapGetters } from "vuex";

export default {
    data() {
        return {
            routeUpdateFromMenu: false
        };
    },
    components: {
        MainTemplate,
        Menu
    },
    computed: {
        ...mapGetters({
            ageVerified: "ageVerification/getAgeVerified",
            pageData: "menu/getPage",
            pagesData: "menu/getPagesData",
            showMenu: "menu/getVisible",
            menu: "menu/getMenu",
            activePage: "menu/getCurrentPageMenuData",
            darkTemplate: "header/getDarkTemplate",
            darkTemplate2: "header/getDarkTemplate2",
            scroll: "scroll/getScroll"
        }),
        pageColor() {
            if (this.darkTemplate) {
                return "#0A141C";
            } else if (!this.darkTemplate2) {
                return "#0A141C";
            } else {
                return "white";
            }
            // return this.darkTemplate ? "#0A141C" : "white";
        }
    },
    methods: {
        ...mapActions({
            getMenuData: "menu/getMenuData",
            setDevice: "resize/setDevice",
            getTranslations: "translations/getTranslations"
        }),
        getDeviceName(width) {
            return width > 1440 ? "desktop" : width < 769 ? "mobile" : "tablet";
        },
        resize() {
            this.setDevice({
                size: innerWidth,
                innerHeight: window.innerHeight,
                type: this.getDeviceName(innerWidth)
            });
        },
        updateScroll() {
            this.scroll.update();
        },
        resizeHandler() {
            this.setDevice({
                size: innerWidth,
                innerHeight: window.innerHeight,
                type: this.getDeviceName(innerWidth)
            });

            window.addEventListener("resize", this.resize);
        }
    },
    mounted() {},
    created() {
        this.resizeHandler();
        this.getTranslations();
    }
};
</script>

<style lang="scss">
$ease-out: cubic-bezier(0.21, 0.22, 0.29, 0.99);
@-webkit-keyframes bounce {
    0%,
    100% {
        -webkit-transform: scale(0);
    }

    50% {
        -webkit-transform: scale(1);
    }
}

@keyframes bounce {
    0%,
    100% {
        transform: scale(0);
        -webkit-transform: scale(0);
    }

    50% {
        transform: scale(1);
        -webkit-transform: scale(1);
    }
}
@-webkit-keyframes rotate {
    100% {
        -webkit-transform: rotate(360deg);
    }
}

@keyframes rotate {
    100% {
        transform: rotate(360deg);
        -webkit-transform: rotate(360deg);
    }
}

.chasing-dots {
    list-style: none;
    position: relative;
    text-align: center;
    margin-right: 30px;
    -webkit-animation: rotate 2s infinite linear;
    animation: rotate 2s infinite linear;
}
.chasing-dots .dot1,
.chasing-dots .dot2 {
    width: 20px;
    height: 20px;
    display: inline-block;
    position: absolute;
    top: 0;
    background-color: #fff;
    border-radius: 100%;
    -webkit-animation: bounce 2s infinite ease-in-out;
    animation: bounce 2s infinite ease-in-out;
}

.chasing-dots .dot2 {
    top: auto;
    bottom: 0;
    -webkit-animation-delay: -1s;
    animation-delay: -1s;
}

.root {
    background: #0a141c;
    transition: background 0.4s $ease-out;
    @media only screen and (max-width: 1023px) {
        //height: 100vh;
        //overflow-y: auto;
        //overflow-x: hidden;
        //position: relative;
    }

    .rout-changer {
        position: absolute;
        left: 0;
        top: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        opacity: 1;
        pointer-events: auto;
        width: 100%;
        height: 100vh;
        background: #0a141c;
        z-index: 99;
        @media only screen and (max-width: 1023px) {
            position: fixed;
        }
    }

    .paging-rout-changer {
        position: absolute;
        left: 0;
        top: 0;
        opacity: 0;
        pointer-events: none;
        width: 100%;
        height: 100vh;
        background: #0a141c;
        z-index: 9;
        @media only screen and (max-width: 1023px) {
            position: fixed;
        }
    }
}
</style>
