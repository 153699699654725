<template>
    <transition name="video">
        <div v-if="videoPath" @click="close" class="video-player">
            <mouse-changer :type="mouseTypes.VideoDown">
                <video controls="" autoplay="" name="media" id="video">
                    <source :src="videoPath" type="video/mp4" />
                </video>
            </mouse-changer>
            <div @click="close" class="close-container">
                <svg
                    class="icon"
                    width="15.556"
                    height="15.556"
                    viewBox="0 0 15.556 15.556"
                >
                    <g
                        id="Group_55"
                        data-name="Group 55"
                        transform="translate(7.778 -4.95) rotate(45)"
                    >
                        <g
                            id="Group_74"
                            data-name="Group 74"
                            transform="translate(0 0)"
                        >
                            <line
                                id="Line_5"
                                data-name="Line 5"
                                x2="18"
                                transform="translate(0 9)"
                                fill="none"
                                stroke="#E5B676"
                                stroke-linecap="round"
                                stroke-width="2"
                            />
                            <line
                                id="Line_15"
                                data-name="Line 15"
                                y2="18"
                                transform="translate(9)"
                                fill="none"
                                stroke="#E5B676"
                                stroke-linecap="round"
                                stroke-width="2"
                            />
                        </g>
                    </g>
                </svg>
            </div>
        </div>
    </transition>
</template>

<script>
import MouseChanger from "@/components/custom/MouseChanger";
import { mapActions } from "vuex";

export default {
    props: {
        videoPath: {
            type: String,
            default: ""
        }
    },
    watch: {
        getPath: {
            immediate: true,
            handler: function() {
                if (this.getPath !== "") {
                    // if (window.innerWidth < 1024) {
                    //     setTimeout(() => {
                    //         this.openFullScreen();
                    //     }, 1000);
                    // }
                }
            }
        }
    },
    components: {
        MouseChanger
    },
    computed: {
        getPath() {
            return this.videoPath;
        }
    },
    methods: {
        ...mapActions({
            setContent: "video/setContent"
        }),
        // openFullScreen() {
        //     var elem = document.getElementById("video");
        //     if (elem.requestFullscreen) {
        //         elem.requestFullscreen();
        //     } else if (elem.webkitRequestFullscreen) {
        //         /* Safari */
        //         elem.webkitRequestFullscreen();
        //     } else if (elem.msRequestFullscreen) {
        //         /* IE11 */
        //         elem.msRequestFullscreen();
        //     }
        //     console.log(elem.requestFullscreen);
        // },
        close() {
            this.setContent(null);
        }
    },
    mounted() {}
};
</script>

<style lang="scss" scoped>
$ease-out: cubic-bezier(0.01, 0.22, 0.07, 0.97);
$ease-in: cubic-bezier(0.24, 0.22, 0.32, 0.87);

.video-player /deep/ {
    @include center-children;
    position: absolute;
    width: 100%;
    height: 100vh;
    z-index: 99;
    left: 0;
    top: 0;
    background: black;
    @media only screen and (max-width: 1023px) {
        position: fixed;
    }
    // @media (max-width: 767px) {
    //     background: black;
    // }

    .mouse-changer {
        width: 100%;
        height: 100%;
    }

    video {
        width: 100%;
        height: 100%;
    }

    .close-container {
        @include circle(60);
        border: 1px solid rgba(193, 148, 50, 0.3);
        display: none;
        right: 50px;
        top: 50px;
        z-index: 3;
        position: absolute;
        align-items: center;
        justify-content: center;
        @media only screen and (max-width: 1023px) {
            display: flex;
            z-index: 2;
        }
    }
}

.video-enter-active,
.video-leave-active {
    transition: all 0.6s $ease-out;
    transform: translateY(0);

    video {
        opacity: 1;
        transition: all 0.4s 0.4s $ease-out;
    }
}

.video-enter,
.video-leave-to {
    transform: translateY(100vh);
    transition: all 0.6s 0.4s $ease-in;

    video {
        opacity: 0;
        transition: all 0.4s $ease-in;
    }
}
</style>
