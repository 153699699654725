<template>
    <div
        @mouseenter="showSupportedLang = true"
        @mouseleave="showSupportedLang = false"
        @click="showSupportedLang = !showSupportedLang"
        class="language-switcher"
    >
        <div
            :class="{ active: showSupportedLang }"
            class="active-language-container"
        >
            <svg
                class="icon"
                width="14.738"
                height="15.99"
                viewBox="0 0 14.738 15.99"
            >
                <g
                    id="Group_55"
                    data-name="Group 55"
                    transform="translate(13.324 14.576) rotate(180)"
                >
                    <line
                        id="Line_3"
                        data-name="Line 3"
                        x2="5.968"
                        y2="6.243"
                        transform="translate(5.943 0)"
                        fill="none"
                        stroke="#E5B676"
                        stroke-linecap="round"
                        stroke-width="2"
                    />
                    <line
                        id="Line_4"
                        data-name="Line 4"
                        x1="5.943"
                        y2="6.243"
                        transform="translate(0 0)"
                        fill="none"
                        stroke="#E5B676"
                        stroke-linecap="round"
                        stroke-width="2"
                    />
                    <line
                        id="Line_5"
                        data-name="Line 5"
                        y2="12.704"
                        transform="translate(5.955 0.872)"
                        fill="none"
                        stroke="#E5B676"
                        stroke-linecap="round"
                        stroke-width="2"
                    />
                </g>
            </svg>
            <span class="title">{{ activeLanguage.title }}.</span>
        </div>
        <div :class="{ active: showSupportedLang }" class="language-list">
            <div
                class="list-item"
                v-for="(item, index) in languages"
                :key="index"
                @click="changeLanguage(item)"
            >
                <span class="title">{{ item.title }}.</span>
            </div>
        </div>
    </div>
</template>

<script>
import { SUPPORTED_LANGUAGES } from "@/constants/trans";
import { mapGetters } from "vuex";
import gsap from "gsap";

export default {
    data() {
        return {
            showSupportedLang: false
        };
    },
    computed: {
        ...mapGetters({
            lang: "language/getLang"
        }),
        languages() {
            return SUPPORTED_LANGUAGES?.filter(item => item.id !== this.lang);
        },
        activeLanguage() {
            return (
                SUPPORTED_LANGUAGES?.find(item => item.id === this.lang) || {}
            );
        }
    },
    mounted() {
        console.log(SUPPORTED_LANGUAGES);
    },
    methods: {
        changeLanguage(item) {
            gsap.to(".rout-changer", {
                opacity: 1,
                pointerEvents: "auto",
                duration: 0.2,
                onComplete: () => {
                    let withoutLanguageParams =
                        this.lang !== this.$route?.params?.lang;
                    let fromPath = withoutLanguageParams
                        ? this.$route?.params?.lang
                        : this.$route?.params?.path1;
                    this.showSupportedLang = false;
                    !fromPath
                        ? this.$router
                              .push({ path: `/${item.id}` })
                              .then(() => {
                                  this.$root.$emit(`language_was_changed`);
                              })
                        : this.$router
                              .push({ params: { lang: item.id } })
                              .then(() => {
                                  this.$root.$emit(`language_was_changed`);
                              });
                }
            });
        }
    }
};
</script>

<style lang="scss" scoped>
$ease-in: cubic-bezier(0.16, 0.34, 0.43, 0.95);
$ease-out: cubic-bezier(0, 0.41, 0.02, 1);
.language-switcher {
    position: relative;
    z-index: 100;
    display: flex;
    justify-content: flex-start;
    width: fit-content;
    pointer-events: auto;

    .title {
        cursor: pointer;
        color: white;
        font-style: italic;
        font-size: 16px;
        display: block;
    }

    .active-language-container {
        display: flex;
        align-items: center;
        cursor: pointer;

        .icon {
            transition: all 0.5s 0.1s $ease-out;
            margin-right: 10px;
        }

        &.active {
            .icon {
                transform: rotate(180deg);
            }
        }
    }

    .language-list {
        position: absolute;
        right: 0;
        top: 100%;
        display: flex;
        flex-direction: column;
        pointer-events: none;

        &.active {
            pointer-events: auto;

            .list-item {
                @for $i from 1 through 3 {
                    &:nth-child(#{$i}) {
                        .title {
                            transition: all 0.5s $i/10 + s $ease-out;
                        }
                    }
                }

                .title {
                    opacity: 1;
                    transform: translateY(0);

                    &:hover {
                        opacity: 0.5;
                    }
                }
            }
        }

        .list-item {
            margin-top: 5px;
            @for $i from 1 through 3 {
                &:nth-child(#{$i}) {
                    .title {
                        transition: all 0.5s (4-$i)/10 + s $ease-in;
                    }
                }
            }

            .title {
                opacity: 0;
                transform: translateY(20px);
            }
        }
    }
}
</style>
