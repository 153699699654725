<template>
    <div ref="cursor" class="custom-cursor">
        <div class="landing-handler handler">
            <div class="landing-content">
                <svg
                    width="42.172"
                    height="46.857"
                    class="icon"
                    viewBox="0 0 42.172 46.857"
                >
                    <g
                        id="Group_55"
                        data-name="Group 55"
                        transform="translate(2.121 2.121)"
                    >
                        <line
                            id="Line_3"
                            data-name="Line 3"
                            x2="19.005"
                            y2="19.881"
                            transform="translate(18.926 0)"
                            fill="none"
                            stroke="#740B43"
                            stroke-linecap="round"
                            stroke-width="3"
                        />
                        <line
                            id="Line_4"
                            data-name="Line 4"
                            x1="18.927"
                            y2="19.881"
                            transform="translate(0 0)"
                            fill="none"
                            stroke="#740B43"
                            stroke-linecap="round"
                            stroke-width="3"
                        />
                        <line
                            id="Line_5"
                            data-name="Line 5"
                            y2="40.458"
                            transform="translate(18.964 2.779)"
                            fill="none"
                            stroke="#740B43"
                            stroke-linecap="round"
                            stroke-width="3"
                        />
                    </g>
                </svg>
                <span class="title">Explore</span>
            </div>
        </div>
        <div class="header-handler handler">
            <span class="title">{{ _tr("Scroll") }}</span>
            <svg
                width="11.775"
                height="22.23"
                class="icon"
                viewBox="0 0 11.775 22.23"
            >
                <g
                    id="Group_55"
                    data-name="Group 55"
                    transform="translate(11.068 21.523) rotate(180)"
                >
                    <line
                        id="Line_3"
                        data-name="Line 3"
                        x2="5.192"
                        y2="5.431"
                        transform="translate(5.17 0)"
                        fill="none"
                        stroke="#E5B676"
                        stroke-linecap="round"
                        stroke-width="1"
                    />
                    <line
                        id="Line_4"
                        data-name="Line 4"
                        x1="5.17"
                        y2="5.431"
                        transform="translate(0 0)"
                        fill="none"
                        stroke="#E5B676"
                        stroke-linecap="round"
                        stroke-width="1"
                    />
                    <line
                        id="Line_5"
                        data-name="Line 5"
                        y2="20.265"
                        transform="translate(5.181 0.759)"
                        fill="none"
                        stroke="#E5B676"
                        stroke-linecap="round"
                        stroke-width="1"
                    />
                </g>
            </svg>
        </div>
        <div
            :style="{ borderColor: color ? color : 'rgba(193, 148, 50, .9)' }"
            class="image-scale-handler handler"
        >
            <svg class="icon" width="20" height="20" viewBox="0 0 20 20">
                <g
                    id="Group_55"
                    data-name="Group 55"
                    transform="translate(1.446 2.966)"
                >
                    <g
                        id="Group_74"
                        data-name="Group 74"
                        transform="translate(-0.5 0.5)"
                    >
                        <line
                            id="Line_5"
                            data-name="Line 5"
                            x2="18"
                            transform="translate(0.054 6.534)"
                            fill="none"
                            :stroke="color ? color : '#fff'"
                            stroke-linecap="round"
                            stroke-width="2"
                        />
                        <line
                            id="Line_15"
                            data-name="Line 15"
                            y2="18"
                            transform="translate(9.054 -2.466)"
                            fill="none"
                            :stroke="color ? color : '#fff'"
                            stroke-linecap="round"
                            stroke-width="2"
                        />
                    </g>
                </g>
            </svg>
        </div>
        <div class="image-down-handler handler">
            <svg
                class="icon"
                width="15.556"
                height="15.556"
                viewBox="0 0 15.556 15.556"
            >
                <g
                    id="Group_55"
                    data-name="Group 55"
                    transform="translate(7.778 -4.95) rotate(45)"
                >
                    <g id="Group_74" data-name="Group 74">
                        <line
                            id="Line_5"
                            data-name="Line 5"
                            x2="18"
                            transform="translate(0 9)"
                            fill="none"
                            stroke="#E5B676"
                            stroke-linecap="round"
                            stroke-width="2"
                        />
                        <line
                            id="Line_15"
                            data-name="Line 15"
                            y2="18"
                            transform="translate(9)"
                            fill="none"
                            stroke="#E5B676"
                            stroke-linecap="round"
                            stroke-width="2"
                        />
                    </g>
                </g>
            </svg>
        </div>
        <div class="video-scale-handler handler">
            <svg
                class="icon"
                width="40.759"
                height="22.708"
                viewBox="0 0 40.759 22.708"
            >
                <g
                    id="Group_55"
                    data-name="Group 55"
                    transform="translate(1.414 1.414)"
                >
                    <line
                        id="Line_3"
                        data-name="Line 3"
                        x2="19.005"
                        y2="19.881"
                        transform="translate(18.926 0)"
                        fill="none"
                        stroke="#fff"
                        stroke-linecap="round"
                        stroke-width="2"
                    />
                    <line
                        id="Line_4"
                        data-name="Line 4"
                        x1="18.927"
                        y2="19.881"
                        transform="translate(0 0)"
                        fill="none"
                        stroke="#fff"
                        stroke-linecap="round"
                        stroke-width="2"
                    />
                    <line
                        id="Line_5"
                        data-name="Line 5"
                        x2="38"
                        transform="translate(0.027 19.881)"
                        fill="none"
                        stroke="#fff"
                        stroke-linecap="round"
                        stroke-width="2"
                    />
                </g>
            </svg>
        </div>
        <div class="explore-handler handler">
            <span class="title">Explore</span>
        </div>
        <div class="slider-handler handler">
            <svg
                class="icon"
                width="16.791"
                height="18.33"
                viewBox="0 0 16.791 18.33"
            >
                <g
                    id="Group_55"
                    data-name="Group 55"
                    transform="translate(1.414 1.414)"
                >
                    <line
                        id="Line_3"
                        data-name="Line 3"
                        x2="6.996"
                        y2="7.318"
                        transform="translate(6.967 0)"
                        fill="none"
                        stroke="#E5B676"
                        stroke-linecap="round"
                        stroke-width="2"
                    />
                    <line
                        id="Line_4"
                        data-name="Line 4"
                        x1="6.967"
                        y2="7.318"
                        transform="translate(0 0)"
                        fill="none"
                        stroke="#E5B676"
                        stroke-linecap="round"
                        stroke-width="2"
                    />
                    <line
                        id="Line_5"
                        data-name="Line 5"
                        y2="14.893"
                        transform="translate(6.981 1.023)"
                        fill="none"
                        stroke="#E5B676"
                        stroke-linecap="round"
                        stroke-width="2"
                    />
                </g>
            </svg>
        </div>
        <div
            :style="{ backgroundColor: color }"
            class="wine-collection-handler handler"
        >
            <div
                :class="{ active: wineCollectionDirection > 0 }"
                class="prev cont"
            >
                <span class="title">Prev</span>
                <svg
                    class="icon"
                    width="22.23"
                    height="11.775"
                    viewBox="0 0 22.23 11.775"
                >
                    <g
                        id="Group_55"
                        data-name="Group 55"
                        transform="translate(0.5 11.068) rotate(-90)"
                    >
                        <line
                            id="Line_3"
                            data-name="Line 3"
                            y1="5.431"
                            x2="5.192"
                            transform="translate(5.17 15.593)"
                            fill="none"
                            stroke="#fff"
                            stroke-linecap="round"
                            stroke-width="1"
                        />
                        <line
                            id="Line_4"
                            data-name="Line 4"
                            x1="5.17"
                            y1="5.431"
                            transform="translate(0 15.593)"
                            fill="none"
                            stroke="#fff"
                            stroke-linecap="round"
                            stroke-width="1"
                        />
                        <line
                            id="Line_5"
                            data-name="Line 5"
                            y1="20.265"
                            transform="translate(5.181 0)"
                            fill="none"
                            stroke="#fff"
                            stroke-linecap="round"
                            stroke-width="1"
                        />
                    </g>
                </svg>
            </div>
            <div
                :class="{ active: wineCollectionDirection < 0 }"
                class="next cont"
            >
                <span class="title">Next</span>
                <svg
                    class="icon"
                    width="22.23"
                    height="11.775"
                    viewBox="0 0 22.23 11.775"
                >
                    <g
                        id="Group_55"
                        data-name="Group 55"
                        transform="translate(0.5 11.068) rotate(-90)"
                    >
                        <line
                            id="Line_3"
                            data-name="Line 3"
                            y1="5.431"
                            x2="5.192"
                            transform="translate(5.17 15.593)"
                            fill="none"
                            stroke="#fff"
                            stroke-linecap="round"
                            stroke-width="1"
                        />
                        <line
                            id="Line_4"
                            data-name="Line 4"
                            x1="5.17"
                            y1="5.431"
                            transform="translate(0 15.593)"
                            fill="none"
                            stroke="#fff"
                            stroke-linecap="round"
                            stroke-width="1"
                        />
                        <line
                            id="Line_5"
                            data-name="Line 5"
                            y1="20.265"
                            transform="translate(5.181 0)"
                            fill="none"
                            stroke="#fff"
                            stroke-linecap="round"
                            stroke-width="1"
                        />
                    </g>
                </svg>
            </div>
        </div>
    </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import gsap from "gsap";

export default {
    props: {
        callback: {
            type: Function,
            required: false
        }
    },
    data() {
        return {
            scaleTimeout: null,
            mouseTimeline: null,
            wineCollectionDirection: 0,
            stuckTimeLine: null
        };
    },
    computed: {
        ...mapGetters({
            cursorEvent: "mouseCursor/getEvent",
            mouseType: "mouseCursor/getMouseType",
            mouseTarget: "mouseCursor/getMouseTarget",
            color: "mouseCursor/getColor"
        }),
        activeMouseTypeHandler() {
            return this[`mouse${this.mouseType}Handler`];
        },
        position() {
            let stuckEl = this.mouseTarget.querySelector(".stuck-area");

            return stuckEl?.getBoundingClientRect();
        },
        cursorSize() {
            return this.device?.size < 1366
                ? {
                      width: 80,
                      height: 80
                  }
                : {
                      width: 92,
                      height: 92
                  };
        }
    },
    methods: {
        ...mapActions({
            setPosition: "mouseCursor/setPosition"
        }),
        defaultMove(event, colored) {
            if (this.scaleTimeout) clearTimeout(this.scaleTimeout);
            let { width, height } = this.cursorSize;
            let x = event.x - width / 2;
            let y = event.y - height / 2;
            gsap.to(".custom-cursor", {
                x,
                y,
                backgroundColor: "transparent",
                borderColor: colored ? "rgba(250,250,250)" : "transparent",
                mixBlendMode: "unset",
                overwrite: true
            });
            gsap.to(".custom-cursor", {
                scale: 0,
                duration: 2.5,
                overwrite: "auto"
            });

            this.scaleTimeout = setTimeout(() => {
                gsap.to(".custom-cursor", {
                    x,
                    y,
                    scale: 1,
                    backgroundColor: "transparent",
                    borderColor: colored ? "rgba(250,250,250)" : "transparent",
                    mixBlendMode: "unset",
                    // ease: 'power1.out',
                    overwrite: true
                });
            }, 1);
        },
        resetAllHandler() {
            if (this.stuckTimeLine) {
                this.stuckTimeLine.pause();
                this.stuckTimeLine.clear();
                let animated = this.mouseTarget.querySelector(".animate");

                if (!animated) return;
                this.gsap.to(animated, {
                    x: 0,
                    y: 0,
                    duration: 0.8,
                    overwrite: true,
                    ease: this.CustomEase.create(
                        "custom",
                        "M0,0,C0.066,0.298,0.202,1,1,1"
                    )
                });
            }

            gsap.to(".custom-cursor .handler", {
                opacity: 0
            });
            this.gsap.to(".custom-cursor", {
                backgroundColor: "transparent",
                borderColor: "transparent",
                mixBlendMode: "unset"
            });
        },
        mouseLandingHandler(event) {
            if (this.scaleTimeout) clearTimeout(this.scaleTimeout);
            let { width, height } = this.cursorSize;
            gsap.to(".custom-cursor", {
                transform: `translate(${event.x - width / 2}px,${event.y -
                    height / 2}px) scale(0)`
            });
            gsap.to(".landing-handler", {
                opacity: 1
            });
            if (event.clientX < innerWidth / 3) {
                gsap.to(".landing-handler .title", {
                    opacity: 0
                });
                gsap.to(".landing-handler .icon", {
                    opacity: 1,
                    transform: "rotate(-90deg)"
                });
                gsap.to(".landing-content", {
                    background: "#e5b676",
                    borderColor: "#93272C"
                });
            } else if (event.clientX > (innerWidth * 2) / 3) {
                gsap.to(".landing-handler .title", {
                    opacity: 0
                });
                gsap.to(".landing-handler .icon", {
                    opacity: 1,
                    transform: "rotate(90deg)"
                });
                gsap.to(".landing-content", {
                    background: "#e5b676",
                    borderColor: "#93272C"
                });
            } else {
                gsap.to(".landing-handler .title", {
                    opacity: 1
                });
                gsap.to(".landing-handler .icon", {
                    opacity: 0
                });
                gsap.to(".landing-content", {
                    background: "white",
                    borderColor: "white"
                });
            }
            this.scaleTimeout = setTimeout(() => {
                gsap.to(".custom-cursor", {
                    transform: `translate(${event.x - width / 2}px,${event.y -
                        height / 2}px) scale(1)`,
                    ease: "power1.out"
                });
            }, 50);
        },
        mouseStuckHandler(event) {
            if (this.scaleTimeout) clearTimeout(this.scaleTimeout);

            let radius = (this.cursorSize.width - this.position.width) / 2;
            this.stuckTimeLine = gsap.timeline();

            let xDelta =
                (this.position.left + this.position.width / 2 - event.clientX) /
                3;
            let yDelta =
                (this.position.top + this.position.width / 2 - event.clientY) /
                3;
            let x = this.position.left - radius - xDelta;
            let y = this.position.top - radius - yDelta;
            let animated = this.mouseTarget.querySelector(".animate");

            this.stuckTimeLine.to(".custom-cursor", {
                x,
                y,
                scale: 1,
                background: "white",
                duration: 0.8,
                overwrite: true,
                ease: this.CustomEase.create(
                    "custom",
                    "M0,0,C0.066,0.298,0.202,1,1,1"
                )
            });

            if (!animated) return;
            this.gsap.to(animated, {
                x: -xDelta,
                y: -yDelta,
                duration: 0.8,
                overwrite: true,
                ease: this.CustomEase.create(
                    "custom",
                    "M0,0,C0.066,0.298,0.202,1,1,1"
                )
            });
        },
        mouseDefaultHandler(event) {
            this.defaultMove(event);
        },
        mouseCustomHandler(event) {
            this.defaultMove(event, true);
        },
        mouseImageScaleHandler(event) {
            this.defaultMove(event);
            gsap.to(".image-scale-handler", {
                opacity: 1
            });
        },
        mouseImageDownHandler(event) {
            this.defaultMove(event);
            gsap.to(".image-down-handler", {
                opacity: 1
            });
        },
        mouseVideoScaleHandler(event) {
            this.defaultMove(event);
            gsap.to(".video-scale-handler", {
                opacity: 1
            });
        },
        mouseVideoDownHandler(event) {
            if (event.clientY > innerHeight - 150) {
                this.resetAllHandler();
                this.mouseDefaultHandler(event);
            } else {
                this.defaultMove(event);
                gsap.to(".image-down-handler", {
                    opacity: 1
                });
            }
        },
        mouseExploreHandler(event) {
            this.defaultMove(event);
            gsap.to(".explore-handler", {
                opacity: 1
            });
        },
        mouseWineCollectionHandler(event) {
            this.defaultMove(event);
            gsap.to(".wine-collection-handler", {
                opacity: 1
            });
            if (event.clientX > innerWidth / 2) {
                this.wineCollectionDirection = -1;
            } else {
                this.wineCollectionDirection = 1;
            }
        },
        mouseSliderHandler(event) {
            this.defaultMove(event);
            gsap.to(".slider-handler", {
                opacity: 1
            });
            if (event.clientX > innerWidth / 2) {
                gsap.to(".slider-handler .icon", {
                    transform: "rotate(90deg)"
                });
            } else {
                gsap.to(".slider-handler .icon", {
                    transform: "rotate(-90deg)"
                });
            }
        },
        mouseHeaderHandler(event) {
            this.defaultMove(event);
            // this.resetAllHandler()
            gsap.to(".header-handler", {
                opacity: 1
            });

            // var body = document.body;

            // body.classList.add("pointer");
        },
        mouseBlendModeHandler(event) {
            let { width, height } = this.cursorSize;

            gsap.to(".custom-cursor", {
                x: event.x - width / 2,
                y: event.y - height / 2,
                mixBlendMode: "difference",
                background: "white"
            });
        },
        mouseMove(event) {
            this.activeMouseTypeHandler(event);
            this.setPosition(event);
            if (typeof this.callback === "function") this.callback(event);
        },
        mouseMoveEventHandler() {
            window.addEventListener("mousemove", this.mouseMove, {
                passive: true
            });
        }
    },
    watch: {
        mouseType: {
            handler: function() {
                this.resetAllHandler();
                // if (this.mouseType !==  this.mouseTypes.Default) {
                // }
            }
        }
    },
    created() {
        this.mouseMoveEventHandler();
    },
    beforeDestroy() {
        window.removeEventListener("mousemove", this.mouseMove);
    }
};
</script>

<style lang="scss">
.custom-cursor {
    border-radius: 50%;
    //border: 1px solid rgba(250, 250, 250, .5);
    width: 130px;
    height: 130px;
    position: absolute;
    z-index: 99;
    pointer-events: none;
    left: 0;
    top: 0;
    border: 1px solid transparent;
    @media only screen and (max-width: 1365px) and (min-width: 1024px) {
        width: 80px;
        height: 80px;
    }

    .handler {
        width: 100%;
        height: 100%;
        left: 0;
        top: 0;
        opacity: 0;
        position: absolute;
    }

    .landing-handler {
        .landing-content {
            width: 100%;
            height: 100%;
            border-radius: 50%;
            @include center-children;
        }

        .title {
            color: #e5b676;
            @include center-absolute;
        }

        .icon {
            transform: rotate(-90deg);
        }
    }

    .header-handler {
        @include center-children;
        border-radius: 50%;
        background: white;

        .title {
            color: #e5b676;
        }

        .icon {
            position: absolute;
            left: 50%;
            transform: translateX(-50%);
            bottom: 15px;
            display: block;
        }
    }

    .image-scale-handler {
        @include circle(60);
        @include center-children;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        border: 2px solid rgba(193, 148, 50, 0.9);
        background: transparent;
    }

    .image-down-handler {
        @include circle(60);
        @include center-children;
        background: white;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        border: 2px solid rgba(255, 255, 255, 0.3);
    }

    .video-scale-handler {
        @include center-children;
        border: 2px solid rgba(193, 148, 50, 0.9);
        border-radius: 50%;
        background: transparent;

        .icon {
            transform: rotate(90deg);
            @media only screen and (max-width: 1365px) and (min-width: 1024px) {
                width: 38px;
            }
        }
    }

    .explore-handler {
        @include center-children;
        background: white;
        border-radius: 50%;
        .title {
            color: #e5b676;
        }
    }

    .wine-collection-handler {
        @include center-children();
        border-radius: 50%;
        color: white;

        .prev,
        .next {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            display: flex;
            flex-direction: column;
            opacity: 0;
            transition: opacity 0.4s ease-out;

            &.active {
                opacity: 1;
            }
        }

        .icon {
            margin-top: 4px;
        }

        .prev {
            align-items: flex-start;

            .icon {
                transform: rotate(180deg);
            }
        }

        .next {
            align-items: flex-end;
        }
    }

    .slider-handler {
        @include circle(60);
        @include center-children;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        border: 2px solid rgba(255, 255, 255, 0.3);
        background: transparent;

        .icon {
            transform: rotate(90deg);
        }
    }

    .blend-mode-handler {
        @include circle(60);
        mix-blend-mode: difference;
        background: white;
    }
}
</style>
