<template>
    <div
        v-observer:callback="observerConfig"
        :id="id"
        :style="{ height: `${contentHeight}px` }"
        :class="{
            left: positionLeft,
            small: small,
            margin: forMobile,
            hide: !show
        }"
        class="fancy-text"
    >
        <div class="fancy-content">
            <div :class="firstElementClass" class="text-container">
                <span :style="{ color: color }" class="title colored">{{
                    title
                }}</span>
            </div>
            <div :class="secondElementClass" class="text-container">
                <span :style="{ color: color }" class="title colored">{{
                    title
                }}</span>
                <span class="title shadow">{{ title }}</span>
            </div>
        </div>
        <div class="line-wrapper-container" v-if="title">
            <div v-if="index" class="dot"></div>
            <div :style="{ background: lineColor }" class="line-wrapper"></div>
            <div v-if="index" class="index">{{ index }}</div>
        </div>
    </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import TitleAnimation from "@/mixins/TitleAnimation";

export default {
    props: {
        forMobile: {
            type: Boolean,
            required: false
        },
        color: {
            type: String,
            required: true
        },
        title: {
            type: String,
            required: false
        },
        reverse: {
            type: Boolean,
            default: false
        },
        positionLeft: {
            type: Boolean,
            default: false
        },
        xPercent: {
            type: Number,
            default: -50
        },
        id: {
            type: String,
            required: true
        },
        index: {
            type: [Number, String],
            required: false
        },
        lineColor: {
            type: String,
            default: "#E5B676"
        },
        small: {
            type: Boolean,
            default: false
        },
        animateOnObserve: {
            type: Boolean,
            default: true
        }
    },
    data() {
        return {
            show: true,
            contentHeight: 0,
            resizeTimeOut: null
        };
    },
    mixins: [TitleAnimation],
    computed: {
        ...mapGetters({
            cursorEvent: "mouseCursor/getEvent",
            disableMouseMove: "mouseCursor/getDisableMouseMove"
        }),
        firstElementClass() {
            return !this.reverse ? "down" : "up";
        },
        secondElementClass() {
            return !this.reverse ? "up" : "down";
        },
        observerConfig() {
            return {
                callBack: this.initTitleAnimation,
                animateOnObserve: this.animateOnObserve
            };
        }
    },
    methods: {
        ...mapActions({
            setDisableMouseMove: "mouseCursor/setDisableMouseMove"
        }),
        resize() {
            if (this.resizeTimeOut) clearTimeout(this.resizeTimeOut);
            this.setDisableMouseMove(true);
            this.gsap.to(this, {
                contentHeight: document.querySelector(
                    `#${this.id} .fancy-content .text-container .title`
                )?.clientHeight
            });
            this.resizeTimeOut = setTimeout(() => {
                this.initTitleAnimation().then(() => {
                    this.setDisableMouseMove(false);
                });
            }, 250);
        },
        resizeHandler() {
            window.addEventListener("resize", this.resize, false);
        }
    },
    mounted() {
        this.$nextTick(() => {
            this.resizeHandler();
            this.contentHeight = document.querySelector(
                `#${this.id} .fancy-content .text-container .title`
            ).clientHeight;
        });
        // this.show = false;
        // setTimeout(() => {
        //     this.show = true;
        // }, 3000);
    },
    beforeDestroy() {
        window.removeEventListener("resize", this.resize);
    }
};
</script>

<style lang="scss" scoped>
$ease-out: cubic-bezier(0.17, 0.71, 0.34, 0.88);

.fancy-text {
    &.hide {
    }
    width: 100%;
    position: absolute;
    z-index: 99;
    left: 50%;
    transform: translate(-50%, -50%);
    @media (max-width: 1023px) {
        &.margin {
            top: -40px !important ;
        }
    }

    .fancy-content {
        height: 100%;
        will-change: transform;
        @media (max-width: 1023px) {
            text-align: left;
            // margin-left: 40px;
            transform: translateX(-50%);
        }
        @media (max-width: 413px) {
            text-align: left;
            // margin-left: 20px;
        }
    }

    &.left {
        .text-container {
            .title {
                left: 0;
                will-change: transform;
            }
        }
    }

    &.small {
        .title {
            font-size: 55px !important;
        }
    }

    .text-container {
        position: absolute;
        width: 100%;
        left: 50%;
        transform: translateX(-50%);
        height: 100%;

        &.up {
            clip-path: inset(-300px 0 50% 0);
        }

        &.down {
            clip-path: inset(50% 0 -300px 0);
            z-index: 1;
        }
    }

    .title {
        font-size: 55px;
        font-weight: normal;
        color: white;
        position: absolute;
        left: 50%;
        display: block;
        letter-spacing: 0.03em;
        will-change: transform;
        opacity: 0;

        &.colored {
            color: #e5b676;
        }

        @media only screen and (max-width: 1439px) and (min-width: 768px) {
            font-size: 55px;
        }
        @media only screen and (max-width: 767px) {
            font-size: 24px;
            width: 100%;
            transform: translateX(-50%) !important;
            text-align: center;
        }
        @media only screen and (max-width: 445px) {
            font-size: 21px;
        }
    }

    .line-wrapper-container {
        .dot {
            @include circle(10);
            background: #e5b676;
            position: absolute;
            top: calc(50% - 40px);
            right: 40px;
        }

        .index {
            color: #e5b676;
            font-size: 16px;
            font-style: italic;
            position: absolute;
            bottom: calc(50% - 40px);
            right: 40px;
        }

        .line-wrapper {
            height: 1px;
            width: 100%;
            background-color: #e5b676;
            position: absolute;
            //left: 0;
            top: 50%;
            transform: translateY(-50%) scaleX(0);
            transform-origin: center;
            z-index: 3;
            will-change: transform;
            //left: -100%;
            @media (max-width: 1365px) {
                width: 100%;
                left: 0%;
            }
        }
    }
}
</style>
